import useEntity from '@/lib/composables/useEntity'

export default function (data) {
  const camundaIncidentTemplate = {
    id: null,
    dateTime: null,
    message: null,
    description: null
  }

  const camundaIncident = useEntity(camundaIncidentTemplate, camundaIncident => {
    camundaIncident.description = camundaIncident.message.length > 73 ? camundaIncident.message.substr(0, 70) + '\u2026' : camundaIncident.message
  })

  if (data) {
    camundaIncident.setData(data)
  }

  return camundaIncident
}
