<template>
  <t-edit-dialog
    id="tenant"
    :item="tenant"
    :input-fields="[
      {
        key: 'id',
        type: 'text',
        label: 'label.id',
        readOnly: true
      },
      {
        key: 'name',
        type: 'text',
        label: 'label.name',
        readOnly: true
      }
    ]"
    :delete-handler="tenant.delete"
    :open-handler="handleOnOpen"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useTenant from '@/app/composables/tenant/useTenant'

export default {
  data () {
    return {
      tenant: useTenant()
    }
  },

  created () {
    useEvent().registerListener('tenant-dialog', 'tenant-delete', () => this.$dialog.closeDialog('tenant'))
  },

  destroyed () {
    useEvent().unregisterListener('invoice-dialog')
  },

  methods: {
    handleOnOpen () {
      this.tenant.setData(this.$dialog.getDialogData('tenant'))
    }
  }
}
</script>
