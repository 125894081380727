<template>
  <t-table
    :default-sort-ascending="false"
    :item-filtering-handler="qrCodeOrderSearch.search"
    :item-properties="[
          {
            key: 'orderDate',
            type: 'date',
            sortable: true
          },
          {
            key: 'quantity',
            type: 'number',
            sortable: true
          },
          {
            key: 'provider',
            sortable: true
          },
          {
            key: 'reference',
            sortable: true
          },
          {
            key: 'receivedDate',
            type: 'date',
            sortable: true
          },
          {
            key: 'processedDate',
            type: 'date',
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: qrCodeOrder => event.fire('qr-code-order-edit', qrCodeOrder)
          }
        ]"
    :items="qrCodeOrderSearch"
    :search="true"
    default-sort-property-key="orderDate"
    item-key="id"
    no-items-label="message.noDataFound"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    qrCodeOrderSearch: null
  },

  data () {
    return {
      event: useEvent()
    }
  }
}
</script>
