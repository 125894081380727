var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-filtering-handler":_vm.qrCodeOrderSearch.search,"item-properties":[
        {
          key: 'orderDate',
          type: 'date',
          sortable: true
        },
        {
          key: 'quantity',
          type: 'number',
          sortable: true
        },
        {
          key: 'provider',
          sortable: true
        },
        {
          key: 'reference',
          sortable: true
        },
        {
          key: 'receivedDate',
          type: 'date',
          sortable: true
        },
        {
          key: 'processedDate',
          type: 'date',
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: function (qrCodeOrder) { return _vm.event.fire('qr-code-order-edit', qrCodeOrder); }
        }
      ],"items":_vm.qrCodeOrderSearch,"search":true,"default-sort-property-key":"orderDate","item-key":"id","no-items-label":"message.noDataFound"}})}
var staticRenderFns = []

export { render, staticRenderFns }