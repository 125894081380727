<template>
  <t-edit-dialog
    id="card-assignment"
    :input-fields="[
          {
            key: 'scannerId',
            type: 'auto-complete-select',
            items: adminScannerSearch.items,
            itemFilteringHandler: adminScannerSearch.search,
            itemByValueHandler: adminScannerSearch.findById,
            itemKey: 'id',
            required: true
          },
          {
            key: 'tenantId',
            type: 'auto-complete-select',
            items: tenantSearch.items,
            itemFilteringHandler: tenantSearch.search,
            itemByValueHandler: tenantSearch.findById,
            itemKey: 'id',
            itemText: 'name',
            label: 'label.tenant',
            required: true
          },
          {
            key: 'numberOfCards',
            type: 'number'
          }
      ]"
    :item="cardAssignment"
    :read-only-for-edit="true"
    :save-handler="cardAssignment.startProcess"
    :uuid-item="true"
  />
</template>

<script>
import useDeviceSearch from '@/app/composables/device/useDeviceSearch'
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useCardAssignment from '@/app/composables/card/useCardAssignment'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      adminScannerSearch: useDeviceSearch('type=SCANNER&adminEnabled=true'),
      tenantSearch: useTenantSearch(),
      cardAssignment: useCardAssignment()
    }
  },

  created () {
    useEvent().registerListener(this, 'card-assignment-add', () => {
      this.cardAssignment.setData(null)
      this.adminScannerSearch.search()
      this.$dialog.openDialog('card-assignment')
    })
    useEvent().registerListener(this, 'card-assignment-edit', cardAssignment => {
      this.cardAssignment.setData(cardAssignment)
      this.$dialog.openDialog('card-assignment')
    })
    useEvent().registerListener(this, ['card-assignment-process-done', 'card-assignment-delete'], () => {
      this.$dialog.closeDialog('card-assignment')
    })
  }
}
</script>
