<template>
  <t-page>
    <t-content-card
      title="label.products"
      :fetch="defaultProductList.loader"
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('default-product-add')
        }
      ]"
    >
      <default-product-table
        :default-product-list="defaultProductList"
      />
    </t-content-card>
  </t-page>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useDefaultProductList from '@/app/composables/product/useDefaultProductList'

export default {
  data () {
    return {
      defaultProductList: useDefaultProductList(),
      event: useEvent(),
      editProduct: null
    }
  },

  created () {
    this.defaultProductList.load()

    useEvent().registerListener(this, ['default-product-delete', 'default-product-save'], () => {
      this.defaultProductList.loader.refresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
