import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const tenantTemplate = {
    id: null,
    name: null,
    devices: null,
    members: null,
    products: null,
    cards: null,
    unassignedCards: null
  }

  const tenant = useEntity(tenantTemplate)

  tenant.refresh = async () => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/tenants/${tenant.id}`)
    await fetch.promise
    tenant.setData(fetch.data)
  }

  tenant.delete = async (editTenant) => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/tenants/${editTenant.id}`)
    if (await fetch.promise) {
      useEvent().fire('tenant-delete', tenant)
    }
  }

  return tenant
}
