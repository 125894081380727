<template>
  <t-content-card
    title="label.cards"
    :fetch="dashboard.fetch"
  >
    <t-table
      :items="[dashboard.card]"
      item-key="id"
      :item-properties="[
            {
              key: 'cards',
              type: 'number'
            },
            {
              key: 'unassigned',
              type: 'number'
            }
          ]"
      :horizontal="true"
    />
  </t-content-card>
</template>

<script>
export default {
  props: {
    dashboard: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
