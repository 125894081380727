import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'

export default function () {
  const subscriptionTemplate = {
    id: null,
    tenantId: null,
    subscriptionDate: null,
    plan: null,
    nextPlan: null,
    currentInvoicingBeginDate: null,
    currentInvoicingEndDate: null,
    currentInvoicingOrders: null,
    remainingFreeOrders: null
  }

  const subscription = Vue.observable(subscriptionTemplate)

  subscription.setData = data => {
    Object.assign(subscription, subscriptionTemplate)
    Object.assign(subscription, data)
  }

  subscription.save = async (editSubscription) => {
    console.log('SAVE')
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/subscriptions/${editSubscription.id}`, {
      body: {
        plan: editSubscription.plan
      }
    })

    await fetch.promise
    if (fetch.isSuccess()) {
      subscription.setData(fetch.data)
      useEvent().fire('subscription-save', subscription)
    }
    return fetch.isSuccess()
  }

  return subscription
}
