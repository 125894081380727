import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const defaultProductTemplate = {
    id: null,
    name: null,
    logoId: null,
    logo: null
  }

  const defaultProduct = useEntity(defaultProductTemplate, defaultProduct => {
    defaultProduct.logo = defaultProduct.logoId ? `${process.env.VUE_APP_TOPUPZ_URL}/product-logos/${defaultProduct.logoId}` : null
  })

  defaultProduct.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/default-products/${defaultProduct.id}`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('default-product-delete')
  }

  defaultProduct.save = async (editDefaultProduct) => {
    let logo
    let logoId
    if (editDefaultProduct.logo.startsWith('data:image/png;base64,')) {
      logo = editDefaultProduct.logo.replace('data:image/png;base64,', '')
    } else if (editDefaultProduct.logo.startsWith(`${process.env.VUE_APP_TOPUPZ_URL}/product-logos/`)) {
      logoId = editDefaultProduct.logo.replace(`${process.env.VUE_APP_TOPUPZ_URL}/product-logos/`, '')
    }

    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/default-products/${editDefaultProduct.id}`, {
      body: {
        name: editDefaultProduct.name,
        logoId,
        logo
      }
    })
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('default-product-save')
  }

  return defaultProduct
}
