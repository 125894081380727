import useSearch from '@/lib/composables/useSearch'
import useQrCode from '@/app/composables/qrcode/useQrCode'
import $fetcher from '@/lib/fetcher'

export default function (filter = '') {
  const qrCodeSearch = useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-codes${filter}`, data => {
    const qrCode = useQrCode()
    qrCode.setData(data)
    return qrCode
  })

  qrCodeSearch.findById = async id => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-codes/${id}`)
    if (await fetch.promise) {
      const qrCode = useQrCode()
      qrCode.setData(fetch.data)
      return qrCode
    }
  }

  return qrCodeSearch
}
