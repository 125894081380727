<template>
  <t-table
    :horizontal="true"
    :item-properties="[
        {
          key: 'definitions',
          type: 'number'
        },
        {
          key: 'instances',
          type: 'number'
        },
        {
          key: 'instancesWithIncident',
          type: 'number',
          icon: 'mdi-refresh',
          iconClickHandler: camundaActions.retryApplicationIncidents
        }
      ]"
    :items="[
        {
          id: '1',
          definitions: camundaStatus.definitions.length,
          instances: camundaStatus.instances,
          instancesWithIncident: camundaStatus.instancesWithIncident
        }
      ]"
    item-key="id"
  />
</template>

<script>
import useCamundaActions from '@/app/composables/camunda/useCamundaActions'

export default {
  props: {
    camundaStatus: {
      required: true
    }
  },

  data () {
    return {
      camundaActions: useCamundaActions()
    }
  }
}
</script>
