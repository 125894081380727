<template>
  <t-page>
    <t-content-card
      title="label.users"
      :fetch="userSearch.loader"
      :actions="[
        {
          icon: 'mdi-file-document-edit',
          handler: () => event.fire('user-terms-renew-action'),
          tooltip: 'label.renewTermsAndConditions'
        }
      ]"
    >

      <user-table :user-search="userSearch"/>

    </t-content-card>

  </t-page>
</template>

<script>
import useUserSearch from '@/app/composables/user/useUserSearch'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      userSearch: useUserSearch(),
      event: useEvent()
    }
  }
}
</script>
