import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const deviceTemplate = {
    id: null,
    type: null,
    tenantId: null,
    name: null,
    model: null,
    applicationVersion: null,
    online: null,
    adminEnabled: null
  }

  const device = useEntity(deviceTemplate)

  device.refresh = async () => {
    await device.findById(device.id)
  }

  device.findById = async id => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/devices/${id}`)
    await fetch.promise
    device.setData(fetch.data)
  }

  device.unlink = async () => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/devices/${device.id}/unlink`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('device-unlink', device)
  }

  device.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/devices/${device.id}`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('device-delete', device)
  }

  device.save = async (editDevice) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/devices/${editDevice.id}`, {
      body: {
        model: editDevice.model,
        adminEnabled: editDevice.adminEnabled,
        type: editDevice.type
      }
    })
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('device-save', device)
  }

  device.resetPin = async () => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/devices/${device.id}/setting`, {
      body: {
        key: 'PIN_CODE',
        value: '1111'
      }
    })
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('device-save', device)
  }

  return device
}
