<template>
  <t-edit-dialog
    id="qr-code"
    :input-fields="[
          {
            key: 'formattedId',
            label: 'label.id',
            type: 'text',
            readOnly: true
          },
          {
            key: 'tenantId',
            type: 'auto-complete-select',
            items: tenantSearch.items,
            itemKey: 'id',
            itemText: 'name',
            itemFilteringHandler: tenantSearch.search,
            itemByValueHandler: tenantSearch.findById,
            readOnly: true
          },
          {
            key: 'name',
            type: 'text',
          },
          {
            key: 'type',
            type: 'select',
            items: qrCodeType.items,
            itemKey: 'key',
            itemText: 'label'
          }
        ]"
    :item="qrCode"
    :save-handler="qrCode.save"
  />
</template>

<script>
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useEvent from '@/lib/composables/useEvent'
import useQrCode from '@/app/composables/qrcode/useQrCode'
import useQrCodeType from '@/app/composables/qrcode/useQrCodeType'

export default {
  data () {
    return {
      qrCode: useQrCode(),
      qrCodeType: useQrCodeType(),
      tenantSearch: useTenantSearch()
    }
  },

  created () {
    useEvent().registerListener(this, 'qr-code-edit', qrCode => {
      this.qrCode.setData(qrCode)
      this.$dialog.openDialog('qr-code')
    })
    useEvent().registerListener(this, ['qr-code-delete', 'qr-code-save'], () => {
      this.$dialog.closeDialog('qr-code')
    })
  }
}
</script>
