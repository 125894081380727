var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-web-app',{attrs:{"menu-items":[
      {
        icon: 'mdi-view-dashboard-outline',
        label: 'label.dashboard',
        route: 'dashboard'
      },
      {
        icon: 'mdi-storefront',
        label: 'label.tenants',
        route: 'tenants'
      },
      {
        icon: 'mdi-text-box-check-outline',
        label: 'label.invoices',
        route: 'invoices',
        badge: _vm.invoicesToApproveCount.value || null
      },
      {
        icon: 'mdi-feather',
        label: 'label.subscriptions',
        route: 'subscriptions'
      },
      {
        icon: 'mdi-account-box-multiple',
        label: 'label.users',
        route: 'users'
      },
      {
        icon: 'mdi-cards',
        label: 'label.cards',
        route: 'cards'
      },
      {
        icon: 'mdi-point-of-sale',
        label: 'label.devices',
        route: 'devices'
      },
      {
        icon: 'mdi-qrcode-scan',
        label: 'label.qrCodes',
        route: 'qr-codes'
      },
      {
        icon: 'mdi-food-fork-drink',
        label: 'label.products',
        route: 'products'
      },
      {
        icon: 'mdi-shopping',
        label: 'label.orders',
        route: 'orders'
      },
      {
        icon: 'mdi-credit-card-plus',
        label: 'label.topups',
        route: 'topups'
      },
      {
        icon: 'mdi-sitemap',
        label: 'Camunda',
        route: 'camunda',
        badge: _vm.camundaInstancesWithIncidentCount.value || null
      }
   ],"title":"label.administration"}}),_c('camunda-definition-model-dialog'),_c('camunda-instance-dialog'),_c('camunda-instance-kill-dialog'),_c('card-assignment-dialog'),_c('card-assignment-process-dialog'),_c('card-dialog'),_c('card-order-dialog'),_c('card-order-error-dialog'),_c('device-dialog'),_c('device-qr-scan-dialog'),_c('device-registration-dialog'),_c('device-unlink-confirmation-dialog'),_c('invoice-dialog'),_c('order-dialog'),_c('default-product-dialog'),_c('qr-code-assignment-dialog'),_c('qr-code-dialog'),_c('qr-code-order-dialog'),_c('qr-code-order-error-dialog'),_c('subscription-dialog'),_c('tenant-dialog'),_c('user-terms-renew-confirmation-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }