import useSearch from '@/lib/composables/useSearch'
import $fetcher from '@/lib/fetcher'
import useDevice from '@/app/composables/device/useDevice'

export default function (queryParams) {
  const deviceSearch = useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/devices${queryParams ? '?' + queryParams : ''}`, data => {
    const device = useDevice()
    device.setData(data)
    return device
  })

  deviceSearch.findById = async deviceId => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/devices/${deviceId}`)
    if (await fetch.promise) {
      const device = useDevice()
      device.setData(fetch.data)
      return device
    }
  }

  return deviceSearch
}
