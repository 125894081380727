import useSearch from '@/lib/composables/useSearch'
import useQrCodeAssignment from '@/app/composables/qrcode/useQrCodeAssignment'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-code-assignments`, data => {
    const qrCodeAssignment = useQrCodeAssignment()
    qrCodeAssignment.setData(data)
    return qrCodeAssignment
  })
}
