<template>
  <t-edit-dialog
    id="qr-code-assignment"
    :input-fields="[
          {
            key: 'tenantId',
            type: 'auto-complete-select',
            items: tenantSearch.items,
            itemFilteringHandler: tenantSearch.search,
            itemByValueHandler: tenantSearch.findById,
            itemKey: 'id',
            itemText: 'name',
            label: 'label.tenant',
            required: true,
            readOnly: !!qrCodeAssignment.id
          },
          {
            key: 'qrCodeIds',
            type: 'auto-complete-list',
            label: 'label.qrCodes',
            items: qrCodeSearch.items,
            itemKey: 'id',
            itemText: 'formattedId',
            itemFilteringHandler: qrCodeSearch.search,
            itemByValueHandler: qrCodeSearch.findById,
            required: true,
            visible: !qrCodeAssignment.id
          },
          {
            key: 'quantity',
            type: 'number',
            visible: !!qrCodeAssignment.id,
            readOnly: true
          },
          {
            key: 'dateTime',
            type: 'date-time',
            visible: !!qrCodeAssignment.id,
            readOnly: true
          }
      ]"
    :item="qrCodeAssignment"
    :save-handler="qrCodeAssignment.save"
    :delete-handler="qrCodeAssignment.delete"
    :uuid-item="true"
  />
</template>

<script>
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useEvent from '@/lib/composables/useEvent'
import useQrCodeAssignment from '@/app/composables/qrcode/useQrCodeAssignment'
import useQrCodeSearch from '@/app/composables/qrcode/useQrCodeSearch'

export default {
  data () {
    return {
      tenantSearch: useTenantSearch(),
      qrCodeSearch: useQrCodeSearch('?tenantId=null'),
      qrCodeAssignment: useQrCodeAssignment()
    }
  },

  created () {
    useEvent().registerListener(this, 'qr-code-assignment-add', () => {
      this.qrCodeAssignment.setData(null)
      this.$dialog.openDialog('qr-code-assignment')
    })
    useEvent().registerListener(this, 'qr-code-assignment-edit', qrCodeAssignment => {
      this.qrCodeAssignment.setData(qrCodeAssignment)
      this.$dialog.openDialog('qr-code-assignment')
    })
    useEvent().registerListener(this, ['qr-code-assignment-save', 'qr-code-assignment-delete'], () => {
      this.$dialog.closeDialog('qr-code-assignment')
    })
  }
}
</script>
