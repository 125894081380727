<template>
  <t-scan-q-r-dialog
    id="device-qr-scan"
    title="label.newDevice"
    info="message.registerNewDeviceInfo"
    :action-handler="handleScanAction"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, 'device-add-action', () => {
      this.$dialog.openDialog('device-qr-scan')
    })
  },

  methods: {
    handleScanAction (qr) {
      if (qr && typeof qr === 'string') {
        qr = JSON.parse(qr)
      }
      this.event.fire('device-scan-action', {
        id: qr ? qr.serial : null,
        type: qr ? qr.type : null,
        model: qr ? qr.model : null
      })
    }
  }
}
</script>
