<template>
  <t-table
    :item-filtering-handler="cardSearch.search"
    :item-properties="[
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'id',
            header: 'label.cardNumber',
            type: 'uuid',
            sortable: true
          },
          {
            key: 'userId',
            header: 'label.username',
            sortable: true
          },
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'initialized',
            type: 'icon',
            icon: card => card.initialized ? { image: 'mdi-shield-key', color: 'primary' } : null,
            sortable: true
          },
          {
            key: 'disabled',
            type: 'icon',
            icon: card => card.disabled ? { image: 'mdi-credit-card-off', color: 'error' } : null,
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: card => event.fire('card-edit', card)
          }
        ]"
    :items="cardSearch"
    :search="true"
    default-sort-property-key="initialized"
    item-key="id"
    no-items-label="message.noCards"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    cardSearch: null
  },

  data () {
    return {
      event: useEvent()
    }
  }
}
</script>
