import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const cardAssignmentTemplate = {
    id: null,
    dateTime: null,
    tenantId: null,
    userId: null,
    numberOfCards: null,
    scannerId: null,
    processUrl: ''
  }

  const cardAssignment = useEntity(cardAssignmentTemplate, cardAssignment => {
    cardAssignment.processUrl = `${process.env.VUE_APP_TOPUPZ_URL}/admin/processes/init-cards/instances/${cardAssignment.id}`
  })

  cardAssignment.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/card-assignments/${cardAssignment.id}`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('card-assignment-delete', cardAssignment)
  }

  cardAssignment.startProcess = async (editCardAssignment) => {
    cardAssignment.id = editCardAssignment.id
    cardAssignment.scannerId = editCardAssignment.scannerId
    cardAssignment.tenantId = editCardAssignment.tenantId
    cardAssignment.numberOfCards = editCardAssignment.numberOfCards

    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/processes/init-cards/instances/${editCardAssignment.id}`, {
      body: {
        scannerId: editCardAssignment.scannerId,
        tenantId: editCardAssignment.tenantId,
        numberOfCards: editCardAssignment.numberOfCards
      }
    })

    await fetch.promise

    fetch.isSuccess() && useEvent().fire('card-assignment-process-start', cardAssignment)
  }

  return cardAssignment
}
