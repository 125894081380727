var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-page',[_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function () { return _vm.event.fire('qr-code-order-add'); }
      }
    ],"collapsible":true,"default-collapsed":true,"fetch":_vm.qrCodeOrderSearch.loader,"title":"label.qrCodeOrders"}},[_c('qr-code-order-table',{attrs:{"qr-code-order-search":_vm.qrCodeOrderSearch}})],1),_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function () { return _vm.event.fire('qr-code-assignment-add'); }
      }
    ],"collapsible":true,"default-collapsed":true,"fetch":_vm.qrCodeAssignmentSearch.loader,"title":"label.qrCodeAssignments"}},[_c('qr-code-assignment-table',{attrs:{"qr-code-assignment-search":_vm.qrCodeAssignmentSearch}})],1),_c('t-content-card',{attrs:{"fetch":_vm.qrCodeSearch.loader,"title":"label.qrCodes"}},[_c('qr-code-table',{attrs:{"qr-code-search":_vm.qrCodeSearch}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }