import Vue from 'vue'
import useLoader from '@/lib/composables/useLoader'

export default function (countHandler) {
  const count = Vue.observable({
    loader: useLoader(),
    value: null
  })

  count.load = async () => {
    await count.loader.load(async () => {
      const countValue = await countHandler()
      if (typeof countValue === 'string') {
        count.value = parseInt(countValue || 0)
      } else {
        count.value = countValue
      }
    })
  }

  count.refresh = async () => {
    return count.loader.refresh()
  }

  count.backgroundRefresh = async () => {
    return count.loader.backgroundRefresh()
  }

  return count
}
