<template>
  <t-edit-dialog
    id="camunda-instance"
    :action-chips="[
      {
        label: 'label.terminate',
        icon: 'mdi-skull',
        handler: () => event.fire('camunda-instance-kill', camundaInstance),
        hideHandler: () => !!camundaInstance.endTime
      }
    ]"
    :input-fields="[
          {
            key: 'businessKey',
            type: 'text'
          },
          {
            key: 'startTime',
            type: 'date-time'
          },
          {
            key: 'endTime',
            type: 'date-time'
          },
          {
            key: 'variables',
            type: 'list',
            itemKey: 'key',
            itemText: 'description'
          },
          {
            key: 'incidents',
            type: 'list',
            itemKey: 'id',
            itemText: 'description',
            inputFields: [
              {
                key: 'dateTime',
                type: 'date-time'
              },
              {
                key: 'message',
                type: 'text',
                multiline: true
              }
            ]
          }
      ]"
    :item="camundaInstance"
    :read-only-for-edit="true"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useCamundaInstance from '@/app/composables/camunda/useCamundaInstance'
import useCamundaActions from '@/app/composables/camunda/useCamundaActions'

export default {
  data () {
    return {
      camundaInstance: useCamundaInstance(),
      camundaActions: useCamundaActions(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, 'camunda-instance-view', camundaInstance => {
      this.camundaInstance.setData(camundaInstance)
      this.$dialog.openDialog('camunda-instance')
    })
    useEvent().registerListener(this, 'camunda-action', () => {
      this.$dialog.closeDialog('camunda-instance')
    })
  }
}
</script>
