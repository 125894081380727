var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{ref:"table",attrs:{"default-sort-ascending":false,"item-properties":[
        {
          key: 'key',
          sortable: true
        },
        {
          key: 'version',
          type: 'number',
          sortable: true
        },
        {
          key: 'instances',
          type: 'number',
          sortable: true
        },
        {
          key: 'instancesWithIncident',
          type: 'number',
          icon: 'mdi-refresh',
          iconClickHandler: _vm.camundaActions.retryDefinitionIncidents,
          sortable: true
        },
        {
          header: 'label.diagram',
          icon: 'mdi-sitemap',
          iconClickHandler: function (definition) { return _vm.event.fire('camunda-definition-model-view', definition); }
        },
        {
          header: 'label.suspension',
          icon: function (definition) { return definition.suspended ? 'mdi-play' : 'mdi-pause'; },
          iconClickHandler: _vm.camundaActions.toggleDefinitionSuspension,
        },
        {
          header: 'label.execute',
          icon: 'mdi-powershell',
          iconClickHandler: _vm.camundaActions.executeDefinition,
        }
      ],"items":_vm.camundaStatus.definitions,"selection-handler":function (definition) { return _vm.event.fire('camunda-definition-select', definition); },"default-sort-property-key":"instances","item-key":"id"}})}
var staticRenderFns = []

export { render, staticRenderFns }