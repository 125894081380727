var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"card-assignment","input-fields":[
        {
          key: 'scannerId',
          type: 'auto-complete-select',
          items: _vm.adminScannerSearch.items,
          itemFilteringHandler: _vm.adminScannerSearch.search,
          itemByValueHandler: _vm.adminScannerSearch.findById,
          itemKey: 'id',
          required: true
        },
        {
          key: 'tenantId',
          type: 'auto-complete-select',
          items: _vm.tenantSearch.items,
          itemFilteringHandler: _vm.tenantSearch.search,
          itemByValueHandler: _vm.tenantSearch.findById,
          itemKey: 'id',
          itemText: 'name',
          label: 'label.tenant',
          required: true
        },
        {
          key: 'numberOfCards',
          type: 'number'
        }
    ],"item":_vm.cardAssignment,"read-only-for-edit":true,"save-handler":_vm.cardAssignment.startProcess,"uuid-item":true}})}
var staticRenderFns = []

export { render, staticRenderFns }