<template>
  <t-table
    :item-filtering-handler="camundaInstanceSearch.search"
    :item-properties="[
          {
            key: 'businessKey'
          },
          {
            key: 'startTime',
            type: 'timestamp'
          },
          {
            key: 'endTime',
            type: 'timestamp'
          },
          {
            key: 'incidents',
            value: instance => instance.incidents ? instance.incidents.length : 0,
            type: 'number',
            icon: 'mdi-refresh',
            iconClickHandler: camundaActions.retryInstanceIncidents,
            disabledHandler: instance => !instance.running
          },
          {
            header: 'label.suspension',
            icon: instance => instance.suspended ? 'mdi-play' : 'mdi-pause',
            iconClickHandler: camundaActions.toggleInstanceSuspension,
            disabledHandler: instance => !instance.running
          },
          {
            header: 'label.details',
            icon: 'mdi-eye',
            iconClickHandler: instance => event.fire('camunda-instance-view', instance)
          }
        ]"
    :items="camundaInstanceSearch"
    item-key="id"
  />
</template>

<script>
import useCamundaActions from '@/app/composables/camunda/useCamundaActions'
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    camundaInstanceSearch: {
      required: true
    }
  },

  data () {
    return {
      camundaActions: useCamundaActions(),
      event: useEvent()
    }
  }
}
</script>
