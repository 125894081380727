import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const invoiceTemplate = {
    id: null,
    tenantId: null,
    number: null,
    date: null,
    description: null,
    amountExcludingVat: null,
    amountIncludingVat: null,
    approved: null,
    paid: null,
    documentCreated: null,
    lines: [],
    approvable: null,
    resendable: null,
    payable: null
  }

  const invoice = useEntity(invoiceTemplate, invoice => {
    invoice.approvable =
      !invoice.approved &&
      !!invoice.description && !!invoice.date && !!invoice.number &&
      !!invoice.lines && !!invoice.lines.length &&
      !!invoice.documentCreated

    invoice.resendable = invoice.approved && !invoice.paid
    invoice.payable = invoice.approved && !invoice.paid
  })

  invoice.refresh = async () => {
    await invoice.findById(invoice.id)
  }

  invoice.findById = async id => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${id}`)
    await fetch.promise
    invoice.setData(fetch.data)
  }

  invoice.approve = async () => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${invoice.id}/approve`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('invoice-approve', invoice)
  }

  invoice.markAsPaid = async () => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${invoice.id}/paid`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('invoice-paid', invoice)
  }

  invoice.resend = async () => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${invoice.id}/resend`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('invoice-resend', invoice)
  }

  invoice.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${invoice.id}`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('invoice-delete', invoice)
  }

  invoice.save = async (editInvoice) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${editInvoice.id}`, {
      body: {
        tenantId: editInvoice.tenantId,
        number: editInvoice.number,
        date: editInvoice.date,
        description: editInvoice.description,
        lines: editInvoice.lines.map(invoiceLine => {
          return {
            description: invoiceLine.description,
            price: invoiceLine.price,
            quantity: invoiceLine.quantity
          }
        })
      }
    })
    await fetch.promise
    if (fetch.isSuccess()) {
      invoice.setData(fetch.data)

      if (editInvoice.document) {
        const payload = new FormData()
        payload.append('document', editInvoice.document)
        const documentSaveFetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${editInvoice.id}/document`, {
          formData: true,
          body: payload
        })
        await documentSaveFetch.promise
        await invoice.refresh()
      }

      useEvent().fire('invoice-save', invoice)
    }
  }

  invoice.downloadDocument = () => {
    $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices/${invoice.id}/document`, {
      bodyType: 'file'
    })
  }

  return invoice
}
