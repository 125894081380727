import useEntity from '@/lib/composables/useEntity'
import useCamundaIncident from '@/app/composables/camunda/useCamundaIncident'

export default function () {
  const camundaInstanceTemplate = {
    id: null,
    businessKey: null,
    startTime: null,
    endTime: null,
    variables: [],
    incidents: []
  }

  const camundaInstance = useEntity(camundaInstanceTemplate, camundaInstance => {
    camundaInstance.incidents = camundaInstance.incidents.map(incident => useCamundaIncident(incident))
    camundaInstance.variables = camundaInstance.variables.map(variable => {
      return {
        ...variable,
        description: `${variable.key} = ${variable.value}`
      }
    })
  })

  return camundaInstance
}
