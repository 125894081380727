<template>
  <t-page>
    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('qr-code-order-add')
        }
      ]"
      :collapsible="true"
      :default-collapsed="true"
      :fetch="qrCodeOrderSearch.loader"
      title="label.qrCodeOrders"
    >
      <qr-code-order-table :qr-code-order-search="qrCodeOrderSearch"/>
    </t-content-card>

    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('qr-code-assignment-add')
        }
      ]"
      :collapsible="true"
      :default-collapsed="true"
      :fetch="qrCodeAssignmentSearch.loader"
      title="label.qrCodeAssignments"
    >
      <qr-code-assignment-table :qr-code-assignment-search="qrCodeAssignmentSearch"/>
    </t-content-card>

    <t-content-card
      :fetch="qrCodeSearch.loader"
      title="label.qrCodes"
    >
      <qr-code-table :qr-code-search="qrCodeSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useEvent from '@/lib/composables/useEvent'
import useQrCodeOrderSearch from '@/app/composables/qrcode/useQrCodeOrderSearch'
import useQrCodeAssignmentSearch from '@/app/composables/qrcode/useQrCodeAssignmentSearch'
import useQrCodeSearch from '@/app/composables/qrcode/useQrCodeSearch'

export default {
  data () {
    return {
      qrCodeOrderSearch: useQrCodeOrderSearch(),
      qrCodeAssignmentSearch: useQrCodeAssignmentSearch(),
      qrCodeSearch: useQrCodeSearch(),
      tenantSearch: useTenantSearch(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, ['qr-code-order-save', 'qr-code-order-delete'], () => {
      this.qrCodeOrderSearch.refresh()
      this.qrCodeAssignmentSearch.refresh()
      this.qrCodeSearch.refresh()
    })
    useEvent().registerListener(this, ['qr-code-assignment-save', 'qr-code-assignment-delete'], () => {
      this.qrCodeAssignmentSearch.refresh()
      this.qrCodeSearch.refresh()
    })
    useEvent().registerListener(this, ['qr-code-save'], () => {
      this.qrCodeSearch.refresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
