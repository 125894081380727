var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"item-filtering-handler":_vm.userSearch.search,"item-properties":[
        {
          key: 'id',
          header: 'label.username',
          sortable: true
        },
        {
          key: 'firstName',
          sortable: true
        },
        {
          key: 'lastName',
          sortable: true
        },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'language',
          sortable: true
        },
        {
          key: 'emailVerified',
          type: 'icon',
          icon: function (user) { return user.emailVerified ? { image: 'mdi-check-circle', color: 'primary' } : null; },
          sortable: true
        },
        {
          key: 'disabled',
          type: 'icon',
          icon: function (user) { return user.disabled ? { image: 'mdi-account-off', color: 'error' } : null; },
          sortable: true
        },
        {
          key: 'tenantMembershipsCount',
          header: 'label.memberships',
          type: 'number',
          sortable: true
        },
        {
          key: 'disabledForTenantsCount',
          header: 'label.disabledMemberships',
          type: 'number',
          sortable: true
        },
        {
          key: 'adminForTenantsCount',
          header: 'label.tenantAdmin',
          type: 'number',
          sortable: true
        },
        {
          key: 'overallAdmin',
          type: 'icon',
          icon: function (user) { return user.overallAdmin ? { image: 'mdi-check-circle', color: 'primary' } : null; },
          sortable: true
        }
      ],"items":_vm.userSearch,"search":true,"default-sort-property-key":"id","item-key":"id","no-items-label":"message.noUsers"}})}
var staticRenderFns = []

export { render, staticRenderFns }