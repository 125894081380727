import useInvoice from '@/app/composables/invoice/useInvoice'
import useSearch from '@/lib/composables/useSearch'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/invoices`, invoiceData => {
    const invoice = useInvoice()
    invoice.setData(invoiceData)
    return invoice
  })
}
