import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const cardTemplate = {
    id: null,
    tenantId: null,
    userId: null,
    name: null,
    initialized: null,
    disabled: null
  }

  const card = useEntity(cardTemplate)

  card.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/cards/${card.id}`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('card-delete', card)
  }

  card.save = async (editCard) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/cards/${editCard.id}`, {
      body: {
        disabled: editCard.disabled
      }
    })
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('card-save', card)
  }

  return card
}
