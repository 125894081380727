<template>
  <t-edit-dialog
    id="card"
    :delete-handler="card.delete"
    :input-fields="[
          {
            key: 'tenantId',
            type: 'auto-complete-select',
            items: tenantSearch.items,
            itemKey: 'id',
            itemText: 'name',
            itemFilteringHandler: tenantSearch.search,
            itemByValueHandler: tenantSearch.findById,
            label: 'label.tenantId',
            readOnly: true
          },
          {
            key: 'id',
            type: 'uuid',
            label: 'label.cardNumber',
            readOnly: true
          },
          {
            key: 'userId',
            type: 'auto-complete-select',
            label: 'label.user',
            items: userSearch.items,
            itemKey: 'id',
            itemText: user => `${user.firstName} ${user.lastName}`,
            itemFilteringHandler: userSearch.search,
            itemByValueHandler: userSearch.findById,
            readOnly: true
          },
          {
            key: 'disabled',
            type: 'switch',
            label: 'label.disabled',
            required: true
          },
        ]"
    :item="card"
    :save-handler="card.save"
    :uuid-item="true"
  />
</template>

<script>
import useCard from '@/app/composables/card/useCard'
import useUserSearch from '@/app/composables/user/useUserSearch'
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      card: useCard(),
      userSearch: useUserSearch(),
      tenantSearch: useTenantSearch()
    }
  },

  created () {
    useEvent().registerListener(this, 'card-edit', card => {
      this.card.setData(card)
      this.$dialog.openDialog('card')
    })
    useEvent().registerListener(this, ['card-delete', 'card-save'], () => {
      this.$dialog.closeDialog('card')
    })
  }
}
</script>
