<template>
  <t-table
    :default-sort-ascending="false"
    :item-filtering-handler="cardAssignmentSearch.search"
    :item-properties="[
          {
            key: 'dateTime',
            type: 'date',
            sortable: true
          },
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'userId',
            sortable: true
          },
          {
            key: 'numberOfCards',
            type: 'number',
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: cardAssignment => event.fire('card-assignment-edit', cardAssignment)
          }
        ]"
    :items="cardAssignmentSearch"
    :search="true"
    default-sort-property-key="dateTime"
    item-key="id"
    no-items-label="message.noCardAssignments"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    cardAssignmentSearch: null
  },

  data () {
    return {
      event: useEvent()
    }
  }
}
</script>
