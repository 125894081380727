<template>
  <t-table
    :default-sort-ascending="false"
    :item-filtering-handler="qrCodeAssignmentSearch.search"
    :item-properties="[
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'quantity',
            type: 'number',
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: qrCodeAssignment => event.fire('qr-code-assignment-edit', qrCodeAssignment)
          }
        ]"
    :items="qrCodeAssignmentSearch"
    :search="true"
    default-sort-property-key="dateTime"
    item-key="id"
    no-items-label="message.noDataFound"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    qrCodeAssignmentSearch: null
  },

  data () {
    return {
      event: useEvent()
    }
  }
}
</script>
