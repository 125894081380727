<template>
  <t-edit-dialog
    id="device-registration"
    :input-fields="[
      {
        key: 'id',
        type: 'text',
        label: 'label.serialNumber',
        required: true
      },
      {
        key: 'type',
        type: 'select',
        label: 'label.type',
        items: ['SCANNER', 'CASHIER'],
        required: true
      },
      {
        key: 'model',
        type: 'text',
        label: 'label.model',
        required: true
      },
      {
        key: 'adminEnabled',
        type: 'switch',
        label: 'label.adminEnabled',
        required: true
      }
    ]"
    :item="device"
    :save-handler="device.save"
    edit-title="label.newDevice"
    new-title="label.newDevice"
  />
</template>

<script>
import useDevice from '@/app/composables/device/useDevice'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      device: useDevice(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, 'device-scan-action', device => {
      this.device.setData(device)
      this.$dialog.openDialog('device-registration')
    })
  }
}
</script>
