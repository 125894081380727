<template>
  <t-content-card
    title="label.tenants"
    :fetch="dashboard.fetch"
  >
    <t-table
      :items="[dashboard.tenant]"
      item-key="id"
      :item-properties="[
            {
              key: 'tenants',
              type: 'number'
            },
            {
              key: 'dutch',
              type: 'number'
            },
            {
              key: 'french',
              type: 'number'
            },
            {
              key: 'english',
              type: 'number'
            }
          ]"
      :horizontal="true"
    />
  </t-content-card>
</template>

<script>
export default {
  props: {
    dashboard: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
