<template>
  <t-confirm-dialog
    id="camunda-instance-kill"
    title="label.terminate"
    message="message.confirmIrrevocableAction"
    :confirm-danger="true"
    :confirm-handler="() => camundaActions.killInstance(camundaInstance)"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useCamundaActions from '@/app/composables/camunda/useCamundaActions'

export default {
  data () {
    return {
      camundaInstance: null,
      camundaActions: useCamundaActions()
    }
  },

  created () {
    useEvent().registerListener(this, 'camunda-instance-kill', camundaInstance => {
      this.camundaInstance = camundaInstance
      this.$dialog.openDialog('camunda-instance-kill')
    })

    useEvent().registerListener(this, 'camunda-action', camundaInstance => {
      this.$dialog.closeDialog('camunda-instance-kill')
    })
  }
}
</script>
