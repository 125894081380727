<template>
  <t-confirm-dialog
    id="device-unlink-confirmation"
    :confirm-danger="true"
    :confirm-handler="device.unlink"
    message="message.confirmUnlink"
    title="label.unlink"
  />
</template>

<script>
import useDevice from '@/app/composables/device/useDevice'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      device: useDevice()
    }
  },

  created () {
    useEvent().registerListener(this, 'device-unlink-action', device => {
      this.device.setData(device)
      this.$dialog.openDialog('device-unlink-confirmation')
    })
    useEvent().registerListener(this, 'device-unlink', device => {
      this.$dialog.closeDialog('device-unlink-confirmation')
    })
  }
}
</script>
