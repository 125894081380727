<template>
  <t-edit-dialog
    id="device"
    :action-chips="[
      {
         label: 'label.resetPin',
         icon: 'mdi-lock-reset',
         closeDialog: true,
         disabledHandler: () => !device.tenantId,
         handler: device.resetPin
       },
       {
         label: 'label.unlink',
         icon: 'mdi-link-off',
         closeDialog: true,
         disabledHandler: () => !device.tenantId || device.type !== 'SCANNER',
         handler: () => event.fire('device-unlink-action', device)
       }
    ]"
    :delete-handler="device.delete"
    :input-fields="[
      {
        key: 'tenantId',
        type: 'auto-complete-select',
        items: tenantSearch.items,
        itemKey: 'id',
        itemText: 'name',
        itemFilteringHandler: tenantSearch.search,
        itemByValueHandler: tenantSearch.findById,
        label: 'label.tenant',
        readOnly: true
      },
      {
        key: 'id',
        type: 'text',
        label: 'label.serialNumber',
        readOnly: true
      },
      {
        key: 'type',
        type: 'select',
        label: 'label.type',
        items: ['SCANNER', 'CASHIER'],
        readOnly: true
      },
      {
        key: 'model',
        type: 'text',
        label: 'label.model',
        required: true
      },
      {
        key: 'adminEnabled',
        type: 'switch',
        label: 'label.adminEnabled',
        required: true,
        disabledHandler: () => !!device.tenantId || device.type !== 'SCANNER'
      }
    ]"
    :item="device"
    :save-handler="device.save"
    :uuid-item="true"
  />
</template>

<script>
import useDevice from '@/app/composables/device/useDevice'
import useEvent from '@/lib/composables/useEvent'
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'

export default {
  data () {
    return {
      device: useDevice(),
      event: useEvent(),
      tenantSearch: useTenantSearch()
    }
  },

  created () {
    useEvent().registerListener(this, 'device-edit', device => {
      this.device.setData(device)
      this.$dialog.openDialog('device')
    })
    useEvent().registerListener(this, ['device-save', 'device-delete'], () => {
      this.$dialog.closeDialog('device')
    })
  }
}
</script>
