var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-page',[_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function () { return _vm.event.fire('card-order-add'); }
      }
    ],"collapsible":true,"default-collapsed":true,"fetch":_vm.cardOrderSearch.loader,"title":"label.cardOrders"}},[_c('card-order-table',{attrs:{"card-order-search":_vm.cardOrderSearch}})],1),_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function () { return _vm.event.fire('card-assignment-add'); }
      }
    ],"collapsible":true,"default-collapsed":true,"fetch":_vm.cardAssignmentSearch.loader,"title":"label.cardAssignments"}},[_c('card-assignment-table',{attrs:{"card-assignment-search":_vm.cardAssignmentSearch}})],1),_c('t-content-card',{attrs:{"fetch":_vm.cardSearch.loader,"title":"label.cards"}},[_c('card-table',{attrs:{"card-search":_vm.cardSearch}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }