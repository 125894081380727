var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"tenant","item":_vm.tenant,"input-fields":[
    {
      key: 'id',
      type: 'text',
      label: 'label.id',
      readOnly: true
    },
    {
      key: 'name',
      type: 'text',
      label: 'label.name',
      readOnly: true
    }
  ],"delete-handler":_vm.tenant.delete,"open-handler":_vm.handleOnOpen}})}
var staticRenderFns = []

export { render, staticRenderFns }