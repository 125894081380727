<template>
  <t-edit-dialog
    id="default-product"
    :item="defaultProduct"
    :uuid-item="true"
    :save-handler="defaultProduct.save"
    :delete-handler="defaultProduct.delete"
    :input-fields="[
        {
          key: 'name',
          type: 'text',
          label: 'label.name',
          required: true
        },
        {
          key: 'logo',
          type: 'logo-choice',
          items: editProductLogos,
          required: true
        }
      ]"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useDefaultProduct from '@/app/composables/product/useDefaultProduct'

export default {
  data () {
    return {
      defaultProduct: useDefaultProduct()
    }
  },

  computed: {
    editProductLogos () {
      return this.defaultProduct.logo ? [this.defaultProduct.logo] : []
    }
  },

  created () {
    useEvent().registerListener(this, 'default-product-edit', defaultProduct => {
      this.defaultProduct.setData(defaultProduct)
      this.$dialog.openDialog('default-product')
    })
    useEvent().registerListener(this, 'default-product-add', () => {
      this.defaultProduct.setData(null)
      this.$dialog.openDialog('default-product')
    })
    useEvent().registerListener(this, ['default-product-delete', 'default-product-save'], () => {
      this.$dialog.closeDialog('default-product')
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
