import $fetcher from '@/lib/fetcher'
import useSearch from '@/lib/composables/useSearch'
import useUser from '@/app/composables/user/useUser'

export default function () {
  const userSearch = useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/users`, data => {
    const user = useUser()
    user.setData(data)
    return user
  })

  userSearch.findById = async id => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/users/${id}`)
    if (await fetch.promise) {
      const user = useUser()
      user.setData(fetch.data)
      return user
    }
  }

  return userSearch
}
