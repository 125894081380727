import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'

export default function () {
  return {
    retryApplicationIncidents () {
      $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/incidents/retry`).promise.then(() => {
        useEvent().fire('camunda-action')
      })
    },

    retryDefinitionIncidents (definition) {
      $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/definitions/${definition.id}/incidents/retry`).promise.then(() => {
        useEvent().fire('camunda-action')
      })
    },

    retryInstanceIncidents (instance) {
      $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/instances/${instance.id}/incidents/retry`).promise.then(() => {
        useEvent().fire('camunda-action')
      })
    },

    toggleDefinitionSuspension (definition) {
      let actionFetch
      if (definition.suspended) {
        actionFetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/definitions/${definition.id}/activate`)
      } else {
        actionFetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/definitions/${definition.id}/suspend`)
      }

      actionFetch.promise.then(() => {
        useEvent().fire('camunda-action')
      })
    },

    toggleInstanceSuspension (instance) {
      let actionFetch
      if (instance.suspended) {
        actionFetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/instances/${instance.id}/activate`)
      } else {
        actionFetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/instances/${instance.id}/suspend`)
      }

      actionFetch.promise.then(() => {
        useEvent().fire('camunda-action')
      })
    },

    executeDefinition (definition) {
      $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/definitions/${definition.id}/execute`).promise.then(() => {
        useEvent().fire('camunda-action')
      })
    },

    killInstance (instance) {
      $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/instances/${instance.id}/kill`).promise.then(() => {
        useEvent().fire('camunda-action')
      })
    }
  }
}
