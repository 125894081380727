<template>
  <t-page>
    <t-content-card
      title="label.tenants"
      :fetch="tenantSearch.loader"
    >
      <tenant-table :tenant-search="tenantSearch" />
    </t-content-card>
  </t-page>
</template>

<script>
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useRefresh from '@/lib/composables/useRefresh'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      tenantSearch: useTenantSearch()
    }
  },

  created () {
    this.tenantSearch.loadEmpty()
    useRefresh().registerListener(this, () => this.tenantSearch.backgroundRefresh())
    useEvent().registerListener(this, 'tenant-delete', () => this.tenantSearch.refresh())
  },

  destroyed () {
    useRefresh().unregisterListener(this)
    useEvent().unregisterListener(this)
  }
}
</script>
