<template>
  <div>
    <t-web-app
      :menu-items="[
        {
          icon: 'mdi-view-dashboard-outline',
          label: 'label.dashboard',
          route: 'dashboard'
        },
        {
          icon: 'mdi-storefront',
          label: 'label.tenants',
          route: 'tenants'
        },
        {
          icon: 'mdi-text-box-check-outline',
          label: 'label.invoices',
          route: 'invoices',
          badge: invoicesToApproveCount.value || null
        },
        {
          icon: 'mdi-feather',
          label: 'label.subscriptions',
          route: 'subscriptions'
        },
        {
          icon: 'mdi-account-box-multiple',
          label: 'label.users',
          route: 'users'
        },
        {
          icon: 'mdi-cards',
          label: 'label.cards',
          route: 'cards'
        },
        {
          icon: 'mdi-point-of-sale',
          label: 'label.devices',
          route: 'devices'
        },
        {
          icon: 'mdi-qrcode-scan',
          label: 'label.qrCodes',
          route: 'qr-codes'
        },
        {
          icon: 'mdi-food-fork-drink',
          label: 'label.products',
          route: 'products'
        },
        {
          icon: 'mdi-shopping',
          label: 'label.orders',
          route: 'orders'
        },
        {
          icon: 'mdi-credit-card-plus',
          label: 'label.topups',
          route: 'topups'
        },
        {
          icon: 'mdi-sitemap',
          label: 'Camunda',
          route: 'camunda',
          badge: camundaInstancesWithIncidentCount.value || null
        }
     ]"
      title="label.administration"
    />

    <camunda-definition-model-dialog />
    <camunda-instance-dialog />
    <camunda-instance-kill-dialog />

    <card-assignment-dialog />
    <card-assignment-process-dialog />
    <card-dialog />
    <card-order-dialog />
    <card-order-error-dialog />

    <device-dialog />
    <device-qr-scan-dialog />
    <device-registration-dialog />
    <device-unlink-confirmation-dialog />

    <invoice-dialog />

    <order-dialog />

    <default-product-dialog />

    <qr-code-assignment-dialog />
    <qr-code-dialog />
    <qr-code-order-dialog />
    <qr-code-order-error-dialog />

    <subscription-dialog />

    <tenant-dialog />

    <user-terms-renew-confirmation-dialog />
  </div>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'
import useInvoicesToApproveCount from '@/app/composables/invoice/useInvoicesToApproveCount'
import useCamundaInstancesWithIncidentCount from '@/app/composables/camunda/useCamundaInstancesWithIncidentCount'

export default {
  data () {
    return {
      invoicesToApproveCount: useInvoicesToApproveCount(),
      camundaInstancesWithIncidentCount: useCamundaInstancesWithIncidentCount()
    }
  },

  created () {
    this.invoicesToApproveCount.load()
    this.camundaInstancesWithIncidentCount.load()

    useRefresh().registerListener(this, () => {
      this.invoicesToApproveCount.backgroundRefresh()
      this.camundaInstancesWithIncidentCount.backgroundRefresh()
    })

    useEvent().registerListener(this, ['invoice-approve', 'invoice-save'], () => {
      this.invoicesToApproveCount.refresh()
    })
  },

  destroyed () {
    useRefresh().unregisterListener(this)
    useEvent().unregisterListener(this)
  }
}
</script>

<style>
  /*@Benny: are you ok with the colors? Is same as in Cashier app. Should this be set in vue base instead?*/
  div.v-dialog:not(.v-dialog--fullscreen) div.v-sheet,
  div.v-dialog:not(.v-dialog--fullscreen) div.v-card,
  div.v-dialog:not(.v-dialog--fullscreen) div.v-card > div.v-list {
    background-color: #424242 !important;
  }
</style>
