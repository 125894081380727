<template>
  <t-table
    :item-filtering-handler="userSearch.search"
    :item-properties="[
          {
            key: 'id',
            header: 'label.username',
            sortable: true
          },
          {
            key: 'firstName',
            sortable: true
          },
          {
            key: 'lastName',
            sortable: true
          },
          {
            key: 'email',
            sortable: true
          },
          {
            key: 'language',
            sortable: true
          },
          {
            key: 'emailVerified',
            type: 'icon',
            icon: user => user.emailVerified ? { image: 'mdi-check-circle', color: 'primary' } : null,
            sortable: true
          },
          {
            key: 'disabled',
            type: 'icon',
            icon: user => user.disabled ? { image: 'mdi-account-off', color: 'error' } : null,
            sortable: true
          },
          {
            key: 'tenantMembershipsCount',
            header: 'label.memberships',
            type: 'number',
            sortable: true
          },
          {
            key: 'disabledForTenantsCount',
            header: 'label.disabledMemberships',
            type: 'number',
            sortable: true
          },
          {
            key: 'adminForTenantsCount',
            header: 'label.tenantAdmin',
            type: 'number',
            sortable: true
          },
          {
            key: 'overallAdmin',
            type: 'icon',
            icon: user => user.overallAdmin ? { image: 'mdi-check-circle', color: 'primary' } : null,
            sortable: true
          }
        ]"
    :items="userSearch"
    :search="true"
    default-sort-property-key="id"
    item-key="id"
    no-items-label="message.noUsers"
  />
</template>

<script>
export default {
  props: {
    userSearch: {
      required: true
    }
  }
}
</script>
