<template>
  <t-message-dialog
    id="card-order-error"
    :message="message"
    title="label.error"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      message: null
    }
  },

  created () {
    useEvent().registerListener(this, 'card-order-error', exceptionCode => {
      if (exceptionCode === 'CSV_INVALID') {
        this.message = 'message.cardOrderCsvInvalid'
      } else if (exceptionCode === 'CSV_DUPLICATE_DATA') {
        this.message = 'message.cardOrderCsvDuplicateData'
      } else if (exceptionCode === 'CSV_QUANTITY_MISMATCH') {
        this.message = 'message.cardOrderCsvQuantityMismatch'
      } else if (exceptionCode === 'CSV_DATA_MISMATCH') {
        this.message = 'message.cardOrderCsvDataMismatch'
      }
      this.$dialog.openDialog('card-order-error')
    })
  }
}
</script>
