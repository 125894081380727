<template>
  <t-page>
    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('device-add-action')
        }
      ]"
      :fetch="deviceSearch.loader"
      title="label.devices"
    >
      <device-table :device-search="deviceSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useDeviceSearch from '@/app/composables/device/useDeviceSearch'

export default {
  data () {
    return {
      deviceSearch: useDeviceSearch(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, ['device-delete', 'device-save', 'device-unlink'], () => {
      this.deviceSearch.refresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
