import useEntity from '@/lib/composables/useEntity'

export default function () {
  const userTemplate = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    language: null,
    timezone: null,
    emailVerified: null,
    disabled: null,
    tenantMembershipsCount: null,
    disabledForTenantsCount: null,
    adminForTenantsCount: null,
    overallAdmin: null
  }

  return useEntity(userTemplate)
}
