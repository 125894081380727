<template>
  <t-table
    :item-properties="[
      {
        key: 'logo',
        type: 'logo'
      },
      {
        key: 'name',
        sortable: true
      },
      {
        header: 'label.edit',
        icon: 'mdi-lead-pencil',
        iconClickHandler: defaultProduct => event.fire('default-product-edit', defaultProduct)
      }
    ]"
    :items="defaultProductList.items"
    :search="true"
    default-sort-property-key="name"
    item-key="id"
    no-items-label="message.noProducts"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    defaultProductList: {
      required: true
    }
  },

  data () {
    return {
      event: useEvent()
    }
  }
}
</script>
