<template>
  <t-edit-dialog
    id="invoice"
    :action-chips="[
        {
          icon: {
            image: 'mdi-check-decagram',
            color: 'primary'
          },
          label: 'label.approve',
          hideHandler: () => !invoice.approvable,
          handler: invoice.approve
        },
        {
          icon: {
            image: 'mdi-check-circle',
            color: 'primary'
          },
          label: 'label.paid',
          hideHandler: () => !invoice.payable,
          handler: invoice.markAsPaid
        },
        {
          icon: 'mdi-email-sync-outline',
          label: 'label.resend',
          hideHandler: () => !invoice.resendable,
          handler: invoice.resend
        },
        {
          icon: 'mdi-file-upload-outline',
          label: 'label.modifyDocument',
          hideHandler: () => modifyDocumentHidden,
          handler: modifyDocumentClicked = true
        },
        {
          icon: 'mdi-file-download-outline',
          label: 'label.downloadDocument',
          hideHandler: () => !invoice.documentCreated,
          handler: invoice.downloadDocument
        }
      ]"
    :input-fields="[
        {
          key: 'tenantId',
          label: 'label.tenant',
          type: 'auto-complete-select',
          required: true,
          items: tenantSearch.items,
          itemKey: 'id',
          itemText: 'name',
          itemFilteringHandler: tenantSearch.search,
          itemByValueHandler: tenantSearch.findById,
          readOnlyForEdit: true
        },
        {
          key: 'description',
          type: 'text',
          required: true
        },
        {
          key: 'date',
          type: 'date',
          required: true
        },
        {
          key: 'number',
          type: 'text',
          required: true
        },
        {
          key: 'lines',
          type: 'list',
          required: true,
          uuidItem: true,
          itemKey: 'id',
          inputFields: [
            {
              key: 'description',
              type: 'text',
              required: true
            },
            {
              key: 'price',
              type: 'amount',
              required: true
            },
            {
              key: 'quantity',
              type: 'number',
              required: true
            },
            {
              key: 'amountExcludingVat',
              type: 'amount',
              readOnly: true
            },
            {
              key: 'amountIncludingVat',
              type: 'amount',
              readOnly: true
            }
          ],
          itemText: line => `${line.description} (€${line.price}) X ${line.quantity}`,
          editHandler: handleInvoiceLineEdit
        },
        {
          key: 'amountExcludingVat',
          type: 'amount',
          readOnly: true
        },
        {
          key: 'amountIncludingVat',
          type: 'amount',
          readOnly: true
        },
        {
          key: 'document',
          type: 'file',
          accept: '.pdf',
          visible: documentVisible
        }
      ]"
    :item="invoice"
    :uuid-item="true"
    :save-handler="invoice.save"
    :delete-handler="invoice.delete"
    :open-handler="handleOnOpen"
    :edit-handler="handleInvoiceEdit"
  />

</template>

<script>
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useInvoice from '@/app/composables/invoice/useInvoice'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      invoice: useInvoice(),
      tenantSearch: useTenantSearch(),
      modifyDocumentClicked: false
    }
  },

  computed: {
    documentVisible () {
      return !this.invoice.documentCreated || this.modifyDocumentClicked
    },

    modifyDocumentHidden () {
      return !this.invoice.documentCreated || this.modifyDocumentClicked
    }
  },

  created () {
    useEvent().registerListener('invoice-dialog', 'invoice-approve', () => this.$dialog.closeDialog('invoice'))
    useEvent().registerListener('invoice-dialog', 'invoice-paid', () => this.$dialog.closeDialog('invoice'))
    useEvent().registerListener('invoice-dialog', 'invoice-delete', () => this.$dialog.closeDialog('invoice'))
    useEvent().registerListener('invoice-dialog', 'invoice-save', invoice => invoice.approved && this.$dialog.closeDialog('invoice'))
  },

  destroyed () {
    useEvent().unregisterListener('invoice-dialog')
  },

  methods: {
    handleOnOpen () {
      this.invoice.setData(this.$dialog.getDialogData('invoice'))
    },

    handleInvoiceEdit (invoice) {
      if (invoice) {
        if (invoice.lines) {
          invoice.amountExcludingVat = invoice.lines.map(line => line.price * line.quantity).reduce((a1, a2) => a1 + a2, 0)
          invoice.amountIncludingVat = Math.round(invoice.lines.map(line => line.price * line.quantity * 1.21).reduce((a1, a2) => a1 + a2, 0) * 100) / 100
        } else {
          invoice.amountExcludingVat = null
          invoice.amountIncludingVat = null
        }
      }
    },

    handleInvoiceLineEdit (line) {
      if (line.price && line.quantity) {
        line.amountExcludingVat = line.price * line.quantity
        line.amountIncludingVat = Math.round(line.price * line.quantity * 1.21 * 100) / 100
      } else {
        line.amountExcludingVat = null
        line.amountIncludingVat = null
      }
    }
  }
}
</script>
