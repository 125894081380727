<template>
  <t-table
    :items="tenantSearch"
    item-key="id"
    :item-properties="[
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'devices',
            type: 'number',
            sortable: false
          },
          {
            key: 'members',
            type: 'number',
            sortable: false
          },
          {
            key: 'products',
            type: 'number',
            sortable: false
          },
          {
            key: 'cards',
            type: 'number',
            sortable: false
          },
          {
            key: 'unassignedCards',
            type: 'number',
            sortable: false
          },
          {
            header: 'label.edit',
            icon: {
              image: 'mdi-lead-pencil',
              clickHandler: tenant => $dialog.openDialog('tenant', tenant)
            }
          },
        ]"
    default-sort-property-key="name"
    :item-filtering-handler="tenantSearch.search"
    :search="true"
    no-items-label="message.noTenants"
  />
</template>

<script>
export default {
  props: {
    tenantSearch: {
      required: true
    }
  }
}
</script>
