var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"subscription","input-fields":[
    {
      key: 'tenantId',
      type: 'text',
      readOnly: true
    },
    {
      key: 'plan',
      label: 'label.plan',
      type: 'list-select',
      items: _vm.subscriptionPlanList.subscriptionPlans,
      itemKey: 'id',
      itemText: 'label',
      itemDescription: 'description',
      itemDisabled: function (subscriptionPlan) { return subscriptionPlan.disabled; }
    }
  ],"item":_vm.subscription,"open-handler":_vm.handleOnOpen,"save-handler":_vm.subscription.save,"uuid-item":true,"edit-subtitle":_vm.subtitle}})}
var staticRenderFns = []

export { render, staticRenderFns }