import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const qrCodeTemplate = {
    id: null,
    tenantId: null,
    name: null,
    type: null
  }

  const qrCode = useEntity(qrCodeTemplate, qrCode => {
    const part1 = qrCode.id.substring(0, 2)
    const part2 = qrCode.id.substring(2, 4)
    const part3 = qrCode.id.substring(4, 6)
    const part4 = qrCode.id.substring(6, 8)
    qrCode.formattedId = `${part1} ${part2} ${part3} ${part4}`.toUpperCase()
  })

  qrCode.save = async (editQrCode) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-codes/${qrCode.id}`, {
      body: {
        name: editQrCode.name,
        type: editQrCode.type
      }
    })
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('qr-code-save', qrCode)
  }

  return qrCode
}
