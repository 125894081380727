<template>
  <t-edit-dialog
    id="card-order"
    :action-chips="[
        {
          label: 'label.downloadCardsCsv',
          icon: 'mdi-download',
          hideForNew: true,
          handler: cardOrder.downloadCardsCsv
        }
    ]"
    :copy-properties="['processedDate']"
    :delete-handler="cardOrder.delete"
    :input-fields="[
        {
          key: 'orderDate',
          type: 'date',
          label: 'label.orderDate',
          required: true
        },
        {
          key: 'quantity',
          type: 'number',
          label: 'label.quantity',
          disabledHandler: item => item.quantity && item.quantity > 0,
          required: true,
          minValue: 1
        },
        {
          key: 'provider',
          type: 'text',
          label: 'label.provider'
        },
        {
          key: 'reference',
          type: 'text',
          label: 'label.reference'
        },
        {
          key: 'receivedDate',
          type: 'date',
          label: 'label.receivedDate'
        },
        {
          key: 'csv',
          type: 'file',
          label: 'label.csvFile',
          accept: '.csv', icon: 'mdi-file-delimited', disabledHandler: item => item.processedDate != null
        }
    ]"
    :item="cardOrder"
    :save-handler="cardOrder.save"
    :uuid-item="true"
  />
</template>

<script>
import useCardOrder from '@/app/composables/card/useCardOrder'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      cardOrder: useCardOrder()
    }
  },

  created () {
    useEvent().registerListener(this, 'card-order-add', () => {
      this.cardOrder.setData(null)
      this.$dialog.openDialog('card-order')
    })
    useEvent().registerListener(this, 'card-order-edit', cardOrder => {
      this.cardOrder.setData(cardOrder)
      this.$dialog.openDialog('card-order')
    })
    useEvent().registerListener(this, ['card-order-save', 'card-order-delete'], () => {
      this.$dialog.closeDialog('card-order')
    })
  }
}
</script>
