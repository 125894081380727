<template>
  <t-page>
    <t-content-card
      title="label.topups"
      :fetch="topupFetch"
    >

      <t-table
        :items="topupFetch"
        item-key="id"
        :item-properties="[
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'amount',
            type: 'amount',
            sortable: true
          },
          {
            key: 'paymentMethod',
            type: 'icon',
            icon: topup => {
              switch (topup.paymentMethod) {
                case 'CASH': return {
                  image: 'mdi-account-cash',
                  tooltip: 'label.cashPayment'
                }
                case 'BANCONTACT': return {
                  image: 't-bancontact',
                  tooltip: 'label.bancontactPayment'
                }
              }
            }
          },
          {
            key: 'userId',
            sortable: true
          },
          {
            key: 'initiator',
            type: 'icon',
            icon: topup => {
              switch (topup.initiator) {
                case 'CASHIER': return {
                  image: 'mdi-cash-register',
                  tooltip: 'label.cashier'
                }
                case 'SCANNER': return {
                  image: 'mdi-credit-card-scan',
                  tooltip: 'label.scanner'
                }
                case 'USER': return {
                  image: 'mdi-account',
                  tooltip: 'label.user'
                }
              }
            }
          },
        ]"
        :search="true"
        :item-filtering-handler="handleTopupFiltering"
        default-sort-property-key="dateTime"
        :default-sort-ascending="false"
        no-items-label="message.noTopups"
      />
    </t-content-card>
  </t-page>
</template>

<script>
export default {
  data () {
    return {
      topupFetch: null
    }
  },

  methods: {
    handleTopupFiltering (filter) {
      let url = `${process.env.VUE_APP_TOPUPZ_URL}/admin/topups`
      url += `?page=${filter.page}`
      url += `&sortProperty=${filter.sortPropertyKey}`
      url += `&sortAscending=${filter.sortAscending}`
      url += `&searchText=${filter.searchText}`
      url += `&searchTimezone=${this.$authorization.user.timezone}`
      this.topupFetch = this.$fetcher.fetch(url)
    }
  }
}
</script>
