import $fetcher from '@/lib/fetcher'
import useEntity from '@/lib/composables/useEntity'
import useLoader from '@/lib/composables/useLoader'

export default function () {
  const camundaModelTemplate = {
    bpmn: null
  }

  const camundaModel = useEntity(camundaModelTemplate)

  camundaModel.loader = useLoader()
  camundaModel.load = async (definitionId) => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/definitions/${definitionId}/model`, { bodyType: 'text' })
    await fetch.promise
    camundaModel.setData({
      bpmn: fetch.data
    })
  }

  return camundaModel
}
