<template>
  <t-dialog
    id="camunda-definition-model"
    :persistent="false"
    :title=title
    max-width="82vw"
  >
    <div class="model-container">
      <div ref="canvas" class="model"/>
    </div>

    <v-card-actions>
      <div class="flex-grow-1"/>
      <t-button label="label.close" @click="$dialog.closeDialog('camunda-definition-model')"/>
    </v-card-actions>
  </t-dialog>
</template>

<script>
import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer'
import useEvent from '@/lib/composables/useEvent'
import useCamundaModel from '@/app/composables/camunda/useCamundaModel'
import useCamundaStatus from '@/app/composables/camunda/useCamundaStatus'
import useRefresh from '@/lib/composables/useRefresh'

export default {
  data () {
    return {
      title: '',
      model: null,
      activities: null,
      definition: null,
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, 'camunda-definition-model-view', async definition => {
      this.title = definition.key
      this.definition = definition
      this.activities = definition.activities

      const camundaModel = useCamundaModel()
      await camundaModel.load(definition.id)
      this.model = camundaModel.bpmn

      this.$dialog.openDialog('camunda-definition-model')
      this.$nextTick(() => {
        this.updateModel()
      })
    })

    useEvent().registerListener(this, ['camunda-activity-instances-click', 'camunda-activity-incidents-click'], () => {
      this.$dialog.closeDialog('camunda-definition-model')
    })

    useRefresh().registerListener(this, async () => {
      if (this.$dialog.isDialogOpen('camunda-definition-model')) {
        const camundaStatus = useCamundaStatus()
        this.definition = camundaStatus.definitions.find(definition => definition.id === this.definition.id)
        if (JSON.stringify(this.activities) !== JSON.stringify(this.definition.activities)) {
          this.activities = this.definition.activities
          await this.updateModel()
        }
      }
    })
  },

  beforeDestroy () {
    this.bpmnViewer && this.bpmnViewer.destroy()
  },

  methods: {
    async updateModel () {
      this.bpmnViewer && this.bpmnViewer.destroy()
      this.bpmnViewer = new NavigatedViewer({
        container: this.$refs.canvas
      })

      await this.bpmnViewer.importXML(this.model)

      const canvas = this.bpmnViewer.get('canvas')
      canvas.zoom('fit-viewport')

      const overlays = this.bpmnViewer.get('overlays')
      const elementRegistry = this.bpmnViewer.get('elementRegistry')

      this.activities.forEach(activity => {
        const activityId = activity.id
        const activityElement = elementRegistry.get(activityId)

        const instancesSpan = activity.instances ? `<span id="${activityId}-instances-badge" class="badge instances-badge">${activity.instances}</span>` : ''
        const incidentsSpan = activity.instancesWithIncident ? `<span id="${activityId}-incidents-badge" class="badge incidents-badge">${activity.instancesWithIncident}</span>` : ''

        overlays.add(activityId, {
          position: {
            top: activityElement.height - 10,
            left: 0
          },
          html: `<div class="badge-container">${instancesSpan}${incidentsSpan}</div>`
        })

        if (instancesSpan) {
          const instancesSpanElement = document.getElementById(`${activityId}-instances-badge`)
          instancesSpanElement.addEventListener('click', event => this.event.fire('camunda-activity-instances-click', activity))
        }

        if (incidentsSpan) {
          const incidentsSpanElement = document.getElementById(`${activityId}-incidents-badge`)
          incidentsSpanElement.addEventListener('click', event => this.event.fire('camunda-activity-incidents-click', activity))
        }
      })
    }
  }
}
</script>

<style>
.model-container {
  padding: 4px;
  width: 100%;
  display: grid;
  place-items: center;
}

.model {
  height: 500px;
  width: 80vw;
  border: 1px solid #9E9E9E;
  background-color: white;
}

.badge-container {
  display: flex;
  flex-direction: row;
}

.badge {
  display: block;
  text-align: center;
  min-height: 20px;
  min-width: 20px;
  border-radius: 20%;
  font-size: 10px;
  border: 1px solid black;
}

.badge:hover {
  cursor: pointer;
  user-select: none;
  font-weight: bold;
}

.instances-badge {
  background: #4CAF50;
  color: #FEFEFE;
}

.incidents-badge {
  background: #FF5252;
  color: #FEFEFE;
  margin-left: 1px;
}
</style>
