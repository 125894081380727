import useSearch from '@/lib/composables/useSearch'
import useCardAssignment from '@/app/composables/card/useCardAssignment'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/card-assignments`, data => {
    const cardAssignment = useCardAssignment()
    cardAssignment.setData(data)
    return cardAssignment
  })
}
