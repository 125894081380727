import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'
import authorization from '@/lib/authorization'
import $notification from '@/lib/notification'

export default function () {
  const cardOrderTemplate = {
    id: null,
    orderDate: null,
    quantity: null,
    provider: null,
    reference: null,
    receivedDate: null,
    processedDate: null
  }

  const cardOrder = useEntity(cardOrderTemplate)

  cardOrder.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/card-orders/${cardOrder.id}`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('card-order-delete', cardOrder)
  }

  cardOrder.save = async (editCardOrder) => {
    const payload = new FormData()
    if (editCardOrder.orderDate) payload.append('orderDate', editCardOrder.orderDate)
    if (editCardOrder.quantity) payload.append('quantity', editCardOrder.quantity)
    if (editCardOrder.reference) payload.append('reference', editCardOrder.reference)
    if (editCardOrder.provider) payload.append('provider', editCardOrder.provider)
    if (editCardOrder.receivedDate) payload.append('receivedDate', editCardOrder.receivedDate)
    if (editCardOrder.csv) payload.append('csv', editCardOrder.csv)

    try {
      // using axios as JS fetch doesn't map response headers in case of errors, fix should be done in Q1 2021
      // https://stackoverflow.com/questions/65273191/cannot-get-response-header-fetch-request-even-with-access-control-expose-headers
      const axios = require('axios')
      await axios.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/card-orders/${editCardOrder.id}`, payload, {
        headers: {
          Authorization: await authorization.getAuthorizationHeader()
        }
      })
      useEvent().fire('card-order-save')
    } catch (error) {
      const exceptionCode = error.response.headers['x-exception-code']
      if (exceptionCode) {
        useEvent().fire('card-order-error', exceptionCode)
      } else {
        $notification.error(error.message)
      }
    }
  }

  cardOrder.downloadCardsCsv = async () => {
    return $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/card-orders/${cardOrder.id}/cards-csv`, {
      bodyType: 'file'
    }).promise
  }

  return cardOrder
}
