import $fetcher from '@/lib/fetcher'

export default function () {
  const userTerms = {}

  userTerms.refresh = async () => [
    $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/admin/users/renew-terms-and-conditions`)
  ]

  return userTerms
}
