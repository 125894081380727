<template>
  <t-edit-dialog
    id="subscription"
    :input-fields="[
      {
        key: 'tenantId',
        type: 'text',
        readOnly: true
      },
      {
        key: 'plan',
        label: 'label.plan',
        type: 'list-select',
        items: subscriptionPlanList.subscriptionPlans,
        itemKey: 'id',
        itemText: 'label',
        itemDescription: 'description',
        itemDisabled: subscriptionPlan => subscriptionPlan.disabled
      }
    ]"
    :item="subscription"
    :open-handler="handleOnOpen"
    :save-handler="subscription.save"
    :uuid-item="true"
    :edit-subtitle="subtitle"
  />
</template>

<script>
import useSubscription from '@/app/composables/subscription/useSubscription'
import useEvent from '@/lib/composables/useEvent'
import useSubscriptionPlanList from '@/app/composables/subscription/useSubscriptionPlanList'

export default {
  data () {
    return {
      subscription: useSubscription(),
      subscriptionPlanList: useSubscriptionPlanList()
    }
  },

  computed: {
    subtitle () {
      if (this.subscriptionPlanList.invoicingSettingsNeeded) {
        return this.$t('message.invoicingInformationRequiredForPaidServices')
      }
      return null
    }
  },

  created () {
    useEvent().registerListener('subscription-dialog', 'subscription-save', () => this.$dialog.closeDialog('subscription'))
  },

  destroyed () {
    useEvent().unregisterListener('subscription-dialog')
  },

  methods: {
    async handleOnOpen () {
      this.subscription.setData(this.$dialog.getDialogData('subscription'))
      await this.subscriptionPlanList.findByTenantId(this.subscription.tenantId)
    }
  }
}
</script>

<style scoped>

</style>
