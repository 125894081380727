<template>
  <t-table
    ref="table"
    :default-sort-ascending="false"
    :item-properties="[
          {
            key: 'key',
            sortable: true
          },
          {
            key: 'version',
            type: 'number',
            sortable: true
          },
          {
            key: 'instances',
            type: 'number',
            sortable: true
          },
          {
            key: 'instancesWithIncident',
            type: 'number',
            icon: 'mdi-refresh',
            iconClickHandler: camundaActions.retryDefinitionIncidents,
            sortable: true
          },
          {
            header: 'label.diagram',
            icon: 'mdi-sitemap',
            iconClickHandler: definition => event.fire('camunda-definition-model-view', definition)
          },
          {
            header: 'label.suspension',
            icon: definition => definition.suspended ? 'mdi-play' : 'mdi-pause',
            iconClickHandler: camundaActions.toggleDefinitionSuspension,
          },
          {
            header: 'label.execute',
            icon: 'mdi-powershell',
            iconClickHandler: camundaActions.executeDefinition,
          }
        ]"
    :items="camundaStatus.definitions"
    :selection-handler="definition => event.fire('camunda-definition-select', definition)"
    default-sort-property-key="instances"
    item-key="id"
  />
</template>

<script>
import useCamundaActions from '@/app/composables/camunda/useCamundaActions'
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    camundaStatus: {
      required: true
    }
  },

  data () {
    return {
      camundaActions: useCamundaActions(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, 'camunda-definition-select', definition => {
      if (definition) {
        this.$refs.table.selectedItemKeys = [definition.id]
      }
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }

}
</script>
