<template>
  <t-confirm-dialog
    :confirm-handler="userTerms.refresh"
    id="user-terms-renew"
    title="label.renewTermsAndConditions"
    message="message.confirmRenewTermsAndConditions"
  />
</template>

<script>
import useUserTerms from '@/app/composables/user/useUserTerms'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      userTerms: useUserTerms()
    }
  },

  created () {
    useEvent().registerListener(this, 'user-terms-renew-action', () => {
      this.$dialog.openDialog('user-terms-renew')
    })
  }
}
</script>
