var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"qr-code-order","action-chips":[
      {
        label: 'label.downloadCsv',
        icon: 'mdi-download',
        hideForNew: true,
        handler: _vm.qrCodeOrder.downloadCsv
      }
  ],"delete-handler":_vm.qrCodeOrder.delete,"input-fields":[
      {
        key: 'orderDate',
        type: 'date',
        required: true
      },
      {
        key: 'quantity',
        type: 'number',
        disabledHandler: function (item) { return !!item.quantity; },
        required: true,
        minValue: 1
      },
      {
        key: 'provider',
        type: 'text',
      },
      {
        key: 'reference',
        type: 'text',
      },
      {
        key: 'receivedDate',
        type: 'date',
      },
      {
        key: 'processedDate',
        type: 'date',
        readOnly: true
      },
      {
        key: 'csv',
        type: 'file',
        label: 'label.csvFile',
        accept: '.csv', icon: 'mdi-file-delimited', disabledHandler: function (item) { return item.processedDate != null; }
      }
  ],"item":_vm.qrCodeOrder,"save-handler":_vm.qrCodeOrder.save,"uuid-item":true}})}
var staticRenderFns = []

export { render, staticRenderFns }