var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"device","action-chips":[
    {
       label: 'label.resetPin',
       icon: 'mdi-lock-reset',
       closeDialog: true,
       disabledHandler: function () { return !_vm.device.tenantId; },
       handler: _vm.device.resetPin
     },
     {
       label: 'label.unlink',
       icon: 'mdi-link-off',
       closeDialog: true,
       disabledHandler: function () { return !_vm.device.tenantId || _vm.device.type !== 'SCANNER'; },
       handler: function () { return _vm.event.fire('device-unlink-action', _vm.device); }
     }
  ],"delete-handler":_vm.device.delete,"input-fields":[
    {
      key: 'tenantId',
      type: 'auto-complete-select',
      items: _vm.tenantSearch.items,
      itemKey: 'id',
      itemText: 'name',
      itemFilteringHandler: _vm.tenantSearch.search,
      itemByValueHandler: _vm.tenantSearch.findById,
      label: 'label.tenant',
      readOnly: true
    },
    {
      key: 'id',
      type: 'text',
      label: 'label.serialNumber',
      readOnly: true
    },
    {
      key: 'type',
      type: 'select',
      label: 'label.type',
      items: ['SCANNER', 'CASHIER'],
      readOnly: true
    },
    {
      key: 'model',
      type: 'text',
      label: 'label.model',
      required: true
    },
    {
      key: 'adminEnabled',
      type: 'switch',
      label: 'label.adminEnabled',
      required: true,
      disabledHandler: function () { return !!_vm.device.tenantId || _vm.device.type !== 'SCANNER'; }
    }
  ],"item":_vm.device,"save-handler":_vm.device.save,"uuid-item":true}})}
var staticRenderFns = []

export { render, staticRenderFns }