<template>
  <t-table
    :items="orderSearch"
    item-key="id"
    :item-properties="[
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'totalAmount',
            type: 'amount',
            sortable: true
          },
          {
            key: 'paymentMethod',
            type: 'text',
            icon: order => {
              switch (order.paymentMethod) {
                case 'TOPUPZ': return {
                  image: 't-topupz',
                  tooltip: 'label.topupzPayment'
                }
                case 'CASH': return {
                  image: 'mdi-account-cash',
                  tooltip: 'label.cashPayment'
                }
                case 'BANCONTACT': return {
                  image: 't-bancontact',
                  tooltip: 'label.bancontactPayment'
                }
              }
            }
          },
          {
            key: 'numberOfProducts',
            type: 'number',
            sortable: true
          },
          {
            header: 'label.details',
            icon: 'mdi-eye',
            iconClickHandler: order => $dialog.openDialog('order', order)
          }
        ]"
    :search="true"
    :item-filtering-handler="orderSearch.search"
    default-sort-property-key="dateTime"
    :default-sort-ascending="false"
    no-items-label="message.noOrders"
  />
</template>

<script>
export default {
  props: {
    orderSearch: {
      required: true
    }
  }
}
</script>
