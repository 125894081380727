import $fetcher from '@/lib/fetcher'
import useTenant from '@/app/composables/tenant/useTenant'
import useSearch from '@/lib/composables/useSearch'

export default function () {
  const tenantSearch = useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/tenants`, tenantData => {
    const tenant = useTenant()
    tenant.setData(tenantData)
    return tenant
  })

  tenantSearch.findById = async tenantId => {
    const tenantFetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/tenants/${tenantId}`)
    if (await tenantFetch.promise) {
      const tenant = useTenant()
      tenant.setData(tenantFetch.data)
      return tenant
    }
  }

  return tenantSearch
}
