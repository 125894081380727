import useSearch from '@/lib/composables/useSearch'
import useCard from '@/app/composables/card/useCard'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/cards`, data => {
    const card = useCard()
    card.setData(data)
    return card
  })
}
