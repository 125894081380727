import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const qrCodeAssignmentTemplate = {
    id: null,
    dateTime: null,
    tenantId: null,
    quantity: null
  }

  const qrCodeAssignment = useEntity(qrCodeAssignmentTemplate)

  qrCodeAssignment.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-code-assignments/${qrCodeAssignment.id}`)
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('qr-code-assignment-delete', qrCodeAssignment)
  }

  qrCodeAssignment.save = async (editQrCodeAssignment) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-code-assignments/${editQrCodeAssignment.id}`, {
      body: {
        tenantId: editQrCodeAssignment.tenantId,
        qrCodeIds: editQrCodeAssignment.qrCodeIds
      }
    })

    await fetch.promise

    fetch.isSuccess() && useEvent().fire('qr-code-assignment-save', qrCodeAssignment)
  }

  return qrCodeAssignment
}
