import useSearch from '@/lib/composables/useSearch'
import useCardOrder from '@/app/composables/card/useCardOrder'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/card-orders`, data => {
    const cardOrder = useCardOrder()
    cardOrder.setData(data)
    return cardOrder
  })
}
