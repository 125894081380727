import $fetcher from '@/lib/fetcher'
import Vue from 'vue'
import useLoader from '@/lib/composables/useLoader'

export default function (definitionId, searchBusinessKey, searchActivityId, searchWithIncident, searchIncludeFinished) {
  const camundaInstanceSearch = Vue.observable({
    loader: useLoader(),
    items: [],
    page: null,
    pageCount: null,
    totalItemCount: null
  })

  camundaInstanceSearch.search = async (filter) => {
    await camundaInstanceSearch.loader.load(async () => {
      const page = filter ? filter.page : 1
      const searchParams = `businessKey=${searchBusinessKey || ''}&activityId=${searchActivityId || ''}&withIncident=${searchWithIncident}&includeFinished=${searchIncludeFinished}&page=${page}`
      const url = `${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/definitions/${definitionId}/instances?${searchParams}`

      const fetch = $fetcher.fetch(url)
      await fetch.promise

      camundaInstanceSearch.items = fetch.data.data
      camundaInstanceSearch.page = fetch.data.page
      camundaInstanceSearch.pageCount = fetch.data.pageCount
      camundaInstanceSearch.totalItemCount = fetch.data.totalItemCount
    })
    return camundaInstanceSearch
  }

  camundaInstanceSearch.refresh = async () => {
    return camundaInstanceSearch.loader.refresh()
  }

  camundaInstanceSearch.backgroundRefresh = async () => {
    return camundaInstanceSearch.loader.backgroundRefresh()
  }

  return camundaInstanceSearch
}
