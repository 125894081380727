import Vue from 'vue'
import VueBase from './lib/vue-base'
import $authorization from './lib/authorization'

import App from '@/app/App'

import CamundaDefinitionModelDialog from '@/app/components/camunda/CamundaDefinitionModelDialog.vue'
import CamundaDefinitionTable from '@/app/components/camunda/CamundaDefinitionTable'
import CamundaInstanceDialog from '@/app/components/camunda/CamundaInstanceDialog'
import CamundaInstanceKillDialog from '@/app/components/camunda/CamundaInstanceKillDialog'
import CamundaInstanceTable from '@/app/components/camunda/CamundaInstanceTable'
import CamundaOverviewTable from '@/app/components/camunda/CamundaOverviewTable'
import CamundaPage from '@/app/components/camunda/CamundaPage'

import CardAssignmentDialog from '@/app/components/card/CardAssignmentDialog'
import CardAssignmentProcessDialog from '@/app/components/card/CardAssignmentProcessDialog'
import CardAssignmentTable from '@/app/components/card/CardAssignmentTable'
import CardDialog from '@/app/components/card/CardDialog'
import CardOrderDialog from '@/app/components/card/CardOrderDialog'
import CardOrderErrorDialog from '@/app/components/card/CardOrderErrorDialog'
import CardOrderTable from '@/app/components/card/CardOrderTable'
import CardPage from '@/app/components/card/CardPage'
import CardTable from '@/app/components/card/CardTable'

import CardDashboard from '@/app/components/dashboard/CardDashboard'
import DashboardPage from '@/app/components/dashboard/DashboardPage'
import DeviceDashboard from '@/app/components/dashboard/DeviceDashboard'
import InvoiceDashboard from '@/app/components/dashboard/InvoiceDashboard'
import SubscriptionDashboard from '@/app/components/dashboard/SubscriptionDashboard'
import TenantDashboard from '@/app/components/dashboard/TenantDashboard'
import UserDashboard from '@/app/components/dashboard/UserDashboard'

import DeviceDialog from '@/app/components/device/DeviceDialog'
import DevicePage from '@/app/components/device/DevicePage'
import DeviceQrScanDialog from '@/app/components/device/DeviceQrScanDialog'
import DeviceRegistrationDialog from '@/app/components/device/DeviceRegistrationDialog'
import DeviceTable from '@/app/components/device/DeviceTable'
import DeviceUnlinkConfirmationDialog from '@/app/components/device/DeviceUnlinkConfirmationDialog'

import InvoiceDialog from '@/app/components/invoice/InvoiceDialog'
import InvoicePage from '@/app/components/invoice/InvoicePage'
import InvoiceTable from '@/app/components/invoice/InvoiceTable'

import OrderDialog from '@/app/components/order/OrderDialog'
import OrderPage from '@/app/components/order/OrderPage'
import OrderTable from '@/app/components/order/OrderTable'

import DefaultProductDialog from '@/app/components/product/DefaultProductDialog'
import DefaultProductTable from '@/app/components/product/DefaultProductTable'
import ProductPage from '@/app/components/product/ProductPage'

import QrCodeAssignmentDialog from '@/app/components/qrcode/QrCodeAssignmentDialog'
import QrCodeAssignmentTable from '@/app/components/qrcode/QrCodeAssignmentTable'
import QrCodeDialog from '@/app/components/qrcode/QrCodeDialog'
import QrCodeOrderDialog from '@/app/components/qrcode/QrCodeOrderDialog'
import QrCodeOrderErrorDialog from '@/app/components/qrcode/QrCodeOrderErrorDialog'
import QrCodeOrderTable from '@/app/components/qrcode/QrCodeOrderTable'
import QrCodePage from '@/app/components/qrcode/QrCodePage'
import QrCodeTable from '@/app/components/qrcode/QrCodeTable'

import SubscriptionDialog from '@/app/components/subscription/SubscriptionDialog'
import SubscriptionPage from '@/app/components/subscription/SubscriptionPage'

import TenantDialog from '@/app/components/tenant/TenantDialog'
import TenantPage from '@/app/components/tenant/TenantPage'
import TenantTable from '@/app/components/tenant/TenantTable'

import TopupPage from '@/app/components/topup/TopupPage'

import UserPage from '@/app/components/user/UserPage'
import UserTable from '@/app/components/user/UserTable'
import UserTermsRenewConfirmationDialog from '@/app/components/user/UserTermsRenewConfirmationDialog'

Vue.component('CamundaDefinitionModelDialog', CamundaDefinitionModelDialog)
Vue.component('CamundaDefinitionTable', CamundaDefinitionTable)
Vue.component('CamundaInstanceDialog', CamundaInstanceDialog)
Vue.component('CamundaInstanceKillDialog', CamundaInstanceKillDialog)
Vue.component('CamundaInstanceTable', CamundaInstanceTable)
Vue.component('CamundaOverviewTable', CamundaOverviewTable)

Vue.component('CardAssignmentDialog', CardAssignmentDialog)
Vue.component('CardAssignmentProcessDialog', CardAssignmentProcessDialog)
Vue.component('CardAssignmentTable', CardAssignmentTable)
Vue.component('CardDialog', CardDialog)
Vue.component('CardOrderDialog', CardOrderDialog)
Vue.component('CardOrderErrorDialog', CardOrderErrorDialog)
Vue.component('CardOrderTable', CardOrderTable)
Vue.component('CardTable', CardTable)

Vue.component('CardDashboard', CardDashboard)
Vue.component('DeviceDashboard', DeviceDashboard)
Vue.component('InvoiceDashboard', InvoiceDashboard)
Vue.component('SubscriptionDashboard', SubscriptionDashboard)
Vue.component('TenantDashboard', TenantDashboard)
Vue.component('UserDashboard', UserDashboard)

Vue.component('InvoiceDialog', InvoiceDialog)
Vue.component('InvoiceTable', InvoiceTable)

Vue.component('OrderDialog', OrderDialog)
Vue.component('OrderTable', OrderTable)

Vue.component('DefaultProductDialog', DefaultProductDialog)
Vue.component('DefaultProductTable', DefaultProductTable)

Vue.component('DeviceDialog', DeviceDialog)
Vue.component('DeviceQrScanDialog', DeviceQrScanDialog)
Vue.component('DeviceRegistrationDialog', DeviceRegistrationDialog)
Vue.component('DeviceTable', DeviceTable)
Vue.component('DeviceUnlinkConfirmationDialog', DeviceUnlinkConfirmationDialog)

Vue.component('QrCodeAssignmentDialog', QrCodeAssignmentDialog)
Vue.component('QrCodeAssignmentTable', QrCodeAssignmentTable)
Vue.component('QrCodeDialog', QrCodeDialog)
Vue.component('QrCodeOrderDialog', QrCodeOrderDialog)
Vue.component('QrCodeOrderErrorDialog', QrCodeOrderErrorDialog)
Vue.component('QrCodeOrderTable', QrCodeOrderTable)
Vue.component('QrCodeTable', QrCodeTable)

Vue.component('SubscriptionDialog', SubscriptionDialog)

Vue.component('TenantDialog', TenantDialog)
Vue.component('TenantTable', TenantTable)

Vue.component('UserTable', UserTable)
Vue.component('UserTermsRenewConfirmationDialog', UserTermsRenewConfirmationDialog)

$authorization.keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL
$authorization.realm = 'topupz'
$authorization.keycloakClientId = 'admin-webapp'
$authorization.keycloakAuthorizationHandler = (user) => {
  return user.roles.includes('admin')
}

$authorization.authenticateUser().then(() => {
  VueBase.init({
    app: App,
    routes: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardPage
      },
      {
        path: '/tenants',
        name: 'tenants',
        component: TenantPage
      },
      {
        path: '/cards',
        name: 'cards',
        component: CardPage
      },
      {
        path: '/devices',
        name: 'devices',
        component: DevicePage
      },
      {
        path: '/products',
        name: 'products',
        component: ProductPage
      },
      {
        path: '/camunda',
        name: 'camunda',
        component: CamundaPage
      },
      {
        path: '/users',
        name: 'users',
        component: UserPage
      },
      {
        path: '/invoices',
        name: 'invoices',
        component: InvoicePage
      },
      {
        path: '/subscriptions',
        name: 'subscriptions',
        component: SubscriptionPage
      },
      {
        path: '/qr-codes',
        name: 'qr-codes',
        component: QrCodePage
      },
      {
        path: '/orders',
        name: 'orders',
        component: OrderPage
      },
      {
        path: '/topups',
        name: 'topups',
        component: TopupPage
      }
    ]
  })
})
