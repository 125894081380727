import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useTranslate from '@/lib/composables/useTranslate'

export default function () {
  const subscriptionPlanList = Vue.observable({
    subscriptionPlans: [],
    invoicingSettingsNeeded: null
  })

  subscriptionPlanList.findByTenantId = async tenantId => {
    subscriptionPlanList.subscriptionPlans = []
    subscriptionPlanList.invoicingSettingsNeeded = null

    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/tenants/${tenantId}/settings`)
    if (await fetch.promise) {
      const tenantSettings = fetch.data

      const translate = useTranslate()

      const freePlan = {
        id: 'FREE',
        label: translate.label.subscriptionPlan_FREE,
        description: translate.message.subscriptionPlan_FREE,
        disabled: false
      }
      const basicPlan = {
        id: 'BASIC',
        label: translate.label.subscriptionPlan_BASIC,
        description: translate.message.subscriptionPlan_BASIC,
        disabled: !tenantSettings.invoicingSettings.completed
      }
      const customPlan = {
        id: 'CUSTOM',
        label: translate.label.subscriptionPlan_CUSTOM,
        description: translate.message.subscriptionPlan_CUSTOM,
        disabled: !tenantSettings.invoicingSettings.completed
      }

      subscriptionPlanList.subscriptionPlans = [freePlan, basicPlan, customPlan]
      subscriptionPlanList.invoicingSettingsNeeded = !tenantSettings.invoicingSettings.completed
    }
  }

  return subscriptionPlanList
}
