import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const qrCodeOrderTemplate = {
    id: null,
    orderDate: null,
    quantity: null,
    provider: null,
    reference: null,
    receivedDate: null,
    processedDate: null
  }

  const qrCodeOrder = useEntity(qrCodeOrderTemplate)

  qrCodeOrder.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-code-orders/${qrCodeOrder.id}`, {
      onError: exceptionCode => useEvent().fire('qr-code-order-error', exceptionCode)
    })

    await fetch.promise
    fetch.isSuccess() && useEvent().fire('qr-code-order-delete', qrCodeOrder)
  }

  qrCodeOrder.save = async (editQrCodeOrder) => {
    const formData = new FormData()
    if (editQrCodeOrder.orderDate) formData.append('orderDate', editQrCodeOrder.orderDate)
    if (editQrCodeOrder.quantity) formData.append('quantity', editQrCodeOrder.quantity)
    if (editQrCodeOrder.reference) formData.append('reference', editQrCodeOrder.reference)
    if (editQrCodeOrder.provider) formData.append('provider', editQrCodeOrder.provider)
    if (editQrCodeOrder.receivedDate) formData.append('receivedDate', editQrCodeOrder.receivedDate)
    if (editQrCodeOrder.csv) formData.append('csv', editQrCodeOrder.csv)

    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-code-orders/${editQrCodeOrder.id}`, {
      body: formData,
      onError: exceptionCode => useEvent().fire('qr-code-order-error', exceptionCode)
    })

    await fetch.promise
    fetch.isSuccess() && useEvent().fire('qr-code-order-save')
  }

  qrCodeOrder.downloadCsv = async () => {
    return $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-code-orders/${qrCodeOrder.id}/csv`, {
      bodyType: 'file'
    }).promise
  }

  return qrCodeOrder
}
