<template>
  <t-process-status-dialog
    id="card-assignment-process"
    :cancel-process-enabled-handler="cancelProcessEnabledHandler"
    :close-handler="closeHandler"
    :loading-text-handler="cardCounter"
    :message2-color-handler="cardStatusColorMapping"
    :message2-handler="lastCardStatusMapping"
    :process-url="cardAssignment.processUrl"
    :status-list-keep-polling="keepPollingList"
    :status-message-mapping="statusMessageMapping"
    cancel-process-button-label="label.stop"
    title="label.initCards"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useCardAssignment from '@/app/composables/card/useCardAssignment'

export default {
  data () {
    return {
      cardAssignment: useCardAssignment(),
      keepPollingList: ['WAITING_FOR_CARD_SCAN'],
      statusMessageMapping: [
        {
          status: 'SCANNER_GONE',
          key: 'message.scannerGone'
        },
        {
          status: 'SCANNER_BUSY',
          key: 'message.scannerBusy'
        },
        {
          status: 'WAITING_FOR_CARD_SCAN',
          key: 'message.cardScanWaiting'
        },
        {
          status: 'DONE',
          key: 'message.initCardsDone'
        }
      ]
    }
  },

  created () {
    useEvent().registerListener(this, 'card-assignment-process-start', cardAssignment => {
      this.cardAssignment.setData(cardAssignment)
      this.$dialog.openDialog('card-assignment-process')
    })
  },

  methods: {
    cancelProcessEnabledHandler (data) {
      return data && (data.status !== 'DONE' && data.status !== 'SCANNER_GONE' && data.status !== 'SCANNER_BUSY')
    },

    closeHandler () {
      useEvent().fire('card-assignment-process-done')
    },

    cardCounter (data) {
      return data.status && (data.status === 'WAITING_FOR_CARD_SCAN' || data.status === 'DONE') ? (data.numberOfCardsRequested ? `${data.cardTotal}/${data.numberOfCardsRequested}` : data.cardTotal) : ''
    },

    lastCardStatusMapping (data) {
      return data.status && data.status === 'WAITING_FOR_CARD_SCAN' ? (data && data.lastCardInitStatus ? this.$t(`message.${data.lastCardInitStatus.toLowerCase()}`) : '') : ''
    },

    cardStatusColorMapping (data) {
      return data && data.lastCardInitStatus === 'CARD_INITIALIZED' ? 'green--text' : 'red--text'
    }
  }
}
</script>
