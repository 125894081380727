import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useLoader from '@/lib/composables/useLoader'
import useDefaultProduct from '@/app/composables/product/useDefaultProduct'

export default function () {
  const defaultProductList = Vue.observable({
    loader: useLoader(),
    items: []
  })

  defaultProductList.load = async () => {
    return defaultProductList.loader.load(async () => {
      const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/default-products`)
      if (await fetch.promise) {
        defaultProductList.items = fetch.data.map(subscriptionData => {
          const defaultProduct = useDefaultProduct()
          defaultProduct.setData(subscriptionData)
          return defaultProduct
        })
      }
    })
  }

  return defaultProductList
}
