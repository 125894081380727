var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"horizontal":true,"item-properties":[
      {
        key: 'definitions',
        type: 'number'
      },
      {
        key: 'instances',
        type: 'number'
      },
      {
        key: 'instancesWithIncident',
        type: 'number',
        icon: 'mdi-refresh',
        iconClickHandler: _vm.camundaActions.retryApplicationIncidents
      }
    ],"items":[
      {
        id: '1',
        definitions: _vm.camundaStatus.definitions.length,
        instances: _vm.camundaStatus.instances,
        instancesWithIncident: _vm.camundaStatus.instancesWithIncident
      }
    ],"item-key":"id"}})}
var staticRenderFns = []

export { render, staticRenderFns }