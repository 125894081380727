import $fetcher from '@/lib/fetcher'
import useEntity from '@/lib/composables/useEntity'
import useLoader from '@/lib/composables/useLoader'

const camundaStatusTemplate = {
  definitions: [],
  instances: null,
  instancesWithIncident: null
}

const camundaStatus = useEntity(camundaStatusTemplate)

camundaStatus.loader = useLoader()
camundaStatus.load = async () => {
  return camundaStatus.loader.load(async () => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/camunda/status`)
    await fetch.promise
    camundaStatus.setData(fetch.data)
  })
}

export default function () {
  return camundaStatus
}
