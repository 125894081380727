var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"card","delete-handler":_vm.card.delete,"input-fields":[
        {
          key: 'tenantId',
          type: 'auto-complete-select',
          items: _vm.tenantSearch.items,
          itemKey: 'id',
          itemText: 'name',
          itemFilteringHandler: _vm.tenantSearch.search,
          itemByValueHandler: _vm.tenantSearch.findById,
          label: 'label.tenantId',
          readOnly: true
        },
        {
          key: 'id',
          type: 'uuid',
          label: 'label.cardNumber',
          readOnly: true
        },
        {
          key: 'userId',
          type: 'auto-complete-select',
          label: 'label.user',
          items: _vm.userSearch.items,
          itemKey: 'id',
          itemText: function (user) { return ((user.firstName) + " " + (user.lastName)); },
          itemFilteringHandler: _vm.userSearch.search,
          itemByValueHandler: _vm.userSearch.findById,
          readOnly: true
        },
        {
          key: 'disabled',
          type: 'switch',
          label: 'label.disabled',
          required: true
        } ],"item":_vm.card,"save-handler":_vm.card.save,"uuid-item":true}})}
var staticRenderFns = []

export { render, staticRenderFns }