<template>
  <t-message-dialog
    id="qr-code-order-error"
    :message="message"
    title="label.error"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      message: null
    }
  },

  created () {
    useEvent().registerListener(this, 'qr-code-order-error', exceptionCode => {
      if (exceptionCode === 'CSV_INVALID') {
        this.message = 'message.qrCodeOrderCsvInvalid'
      } else if (exceptionCode === 'CSV_DUPLICATE_DATA') {
        this.message = 'message.qrCodeOrderCsvDuplicateData'
      } else if (exceptionCode === 'CSV_QUANTITY_MISMATCH') {
        this.message = 'message.qrCodeOrderCsvQuantityMismatch'
      } else if (exceptionCode === 'CSV_DATA_MISMATCH') {
        this.message = 'message.qrCodeOrderCsvDataMismatch'
      } else if (exceptionCode === 'DELETE_ASSIGNED') {
        this.message = 'message.qrCodeOrderDeleteAssigned'
      } else {
        this.message = null
      }

      if (this.message) {
        this.$dialog.openDialog('qr-code-order-error')
      }
    })
  }
}
</script>
