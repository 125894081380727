var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"camunda-instance","action-chips":[
    {
      label: 'label.terminate',
      icon: 'mdi-skull',
      handler: function () { return _vm.event.fire('camunda-instance-kill', _vm.camundaInstance); },
      hideHandler: function () { return !!_vm.camundaInstance.endTime; }
    }
  ],"input-fields":[
        {
          key: 'businessKey',
          type: 'text'
        },
        {
          key: 'startTime',
          type: 'date-time'
        },
        {
          key: 'endTime',
          type: 'date-time'
        },
        {
          key: 'variables',
          type: 'list',
          itemKey: 'key',
          itemText: 'description'
        },
        {
          key: 'incidents',
          type: 'list',
          itemKey: 'id',
          itemText: 'description',
          inputFields: [
            {
              key: 'dateTime',
              type: 'date-time'
            },
            {
              key: 'message',
              type: 'text',
              multiline: true
            }
          ]
        }
    ],"item":_vm.camundaInstance,"read-only-for-edit":true}})}
var staticRenderFns = []

export { render, staticRenderFns }