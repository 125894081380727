<template>
  <t-page>
    <t-content-card
      :fetch="invoiceSearch.fetch"
      title="label.invoices"
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => $dialog.openDialog('invoice')
        }
      ]"
    >
      <invoice-table :invoice-search="invoiceSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useInvoiceSearch from '@/app/composables/invoice/useInvoiceSearch'
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'

export default {
  data () {
    return {
      invoiceSearch: useInvoiceSearch()
    }
  },

  created () {
    useRefresh().registerListener('invoice-page', () => this.invoiceSearch.backgroundRefresh())
    useEvent().registerListener('invoice-page', 'invoice-approve', () => this.invoiceSearch.refresh())
    useEvent().registerListener('invoice-page', 'invoice-paid', () => this.invoiceSearch.refresh())
    useEvent().registerListener('invoice-page', 'invoice-delete', () => this.invoiceSearch.refresh())
    useEvent().registerListener('invoice-page', 'invoice-save', () => this.invoiceSearch.refresh())
  },

  destroyed () {
    useRefresh().unregisterListener('invoice-page')
    useEvent().unregisterListener('invoice-page')
  }
}
</script>

<style scoped>

</style>
