import Vue from 'vue'
import $fetcher from '@/lib/fetcher'

const dashboard = Vue.observable({
  fetch: null,
  tenant: {
    tenants: null,
    dutch: null,
    english: null,
    french: null
  },
  subscription: {
    free: null,
    basic: null,
    custom: null
  },
  device: {
    devices: null,
    unassigned: null,
    scanners: null,
    cashiers: null,
    onlineDevices: null,
    onlineScanners: null,
    onlineCashiers: null
  },
  invoice: {
    toApprove: null,
    toPay: null
  },
  user: {
    users: null,
    disabled: null,
    unverified: null
  },
  card: {
    cards: null,
    unassigned: null
  },
  camunda: {
    instancesWithIncident: null
  }
})

dashboard.setData = data => {
  Object.assign(dashboard, data)
}

dashboard.refresh = async () => {
  if (!dashboard.fetch) {
    dashboard.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/dashboard`)
  } else {
    dashboard.fetch.fetch()
  }
  if (await dashboard.fetch.promise) {
    dashboard.setData(dashboard.fetch.data)
  }
}

dashboard.backgroundRefresh = async () => {
  if (dashboard.fetch && !dashboard.fetch.isPending()) {
    dashboard.fetch.executeFetch()
    if (await dashboard.fetch.promise) {
      dashboard.setData(dashboard.fetch.data)
    }
  }
}

export default function () {
  if (!dashboard.fetch) {
    dashboard.refresh()
  }
  return dashboard
}
