var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"item-filtering-handler":_vm.camundaInstanceSearch.search,"item-properties":[
        {
          key: 'businessKey'
        },
        {
          key: 'startTime',
          type: 'timestamp'
        },
        {
          key: 'endTime',
          type: 'timestamp'
        },
        {
          key: 'incidents',
          value: function (instance) { return instance.incidents ? instance.incidents.length : 0; },
          type: 'number',
          icon: 'mdi-refresh',
          iconClickHandler: _vm.camundaActions.retryInstanceIncidents,
          disabledHandler: function (instance) { return !instance.running; }
        },
        {
          header: 'label.suspension',
          icon: function (instance) { return instance.suspended ? 'mdi-play' : 'mdi-pause'; },
          iconClickHandler: _vm.camundaActions.toggleInstanceSuspension,
          disabledHandler: function (instance) { return !instance.running; }
        },
        {
          header: 'label.details',
          icon: 'mdi-eye',
          iconClickHandler: function (instance) { return _vm.event.fire('camunda-instance-view', instance); }
        }
      ],"items":_vm.camundaInstanceSearch,"item-key":"id"}})}
var staticRenderFns = []

export { render, staticRenderFns }