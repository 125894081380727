<template>
  <t-page>
    <t-content-card
      title="label.subscriptions"
      :fetch="subscriptionList.fetch"
    >

      <t-table
        :items="subscriptionList.subscriptions"
        item-key="id"
        :item-properties="[
          {
            key: 'tenantId',
            sortable: true
          },
          {
            key: 'subscriptionDate',
            type: 'date',
            sortable: true
          },
          {
            key: 'plan',
            value: subscriptionPeriod => $t('label.subscriptionPlan_' + subscriptionPeriod.plan),
            sortable: true
          },
          {
            key: 'nextPlan',
            value: subscriptionPeriod => subscriptionPeriod.nextPlan ? $t('label.subscriptionPlan_' + subscriptionPeriod.nextPlan) : null,
            sortable: true
          },
          {
            key: 'currentInvoicingBeginDate',
            type: 'date',
            sortable: true
          },
          {
            key: 'currentInvoicingEndDate',
            type: 'date',
            sortable: true
          },
          {
            key: 'currentInvoicingOrders',
            type: 'number',
            sortable: true
          },
          {
            key: 'remainingFreeOrders',
            type: 'number',
            sortable: true
          },
          {
            header: 'label.edit',
            icon: {
              image: 'mdi-lead-pencil',
              clickHandler: subscription => $dialog.openDialog('subscription', subscription)
            }
          },
        ]"
        default-sort-property-key="tenantId"
        :search="true"
      />
    </t-content-card>
  </t-page>
</template>

<script>
import useSubscriptionList from '@/app/composables/subscription/useSubscriptionList'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      subscriptionList: useSubscriptionList()
    }
  },

  created () {
    this.subscriptionList.refresh()
    useEvent().registerListener('subscription-page', 'subscription-save', () => this.subscriptionList.refresh())
  },

  destroyed () {
    useEvent().unregisterListener('subscription-page')
  }
}
</script>

<style scoped>

</style>
