<template>
  <div class="fill-height">

    <t-content-card
      :fetch="camundaStatus.loader"
      title="label.overview"
    >
      <camunda-overview-table :camunda-status="camundaStatus"/>
    </t-content-card>

    <t-content-card
      :fetch="camundaStatus.loader"
      title="label.definitions"
    >
      <camunda-definition-table :camunda-status="camundaStatus"/>
    </t-content-card>

    <t-content-card
      v-if="selectedDefinitionId"
      :fetch="camundaInstanceSearch.loader"
      title="label.instances"
    >

      <div class="d-flex flex-row" style="gap: 24px;">
        <t-text-field
          v-model="searchBusinessKey"
          label="label.businessKey"
          @keydown.enter="() => searchInstances()"
        />

        <t-auto-complete-select
          v-model="searchActivityId"
          :item-text="item => item.name ? `${item.name} (${item.id})` : item.id"
          :items="searchActivities"
          item-key="id"
          label="label.activity"
          style="max-width: 400px"
        />
      </div>

      <div class="d-flex flex-row" style="gap: 24px;">
        <t-switch-field
          v-model="searchWithIncident"
          label="label.incident"
        />

        <t-switch-field
          v-model="searchIncludeFinished"
          label="label.includeFinished"
        />
      </div>

      <t-button
        primary
        label="label.search"
        @click="() => searchInstances()"
      />

      <camunda-instance-table
        :camunda-instance-search="camundaInstanceSearch"
        :definition-id="selectedDefinitionId"
      />

    </t-content-card>
  </div>
</template>

<script>
import useCamundaStatus from '@/app/composables/camunda/useCamundaStatus'
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'
import useCamundaInstanceSearch from '@/app/composables/camunda/useCamundaInstanceSearch'

export default {
  data () {
    return {
      camundaStatus: useCamundaStatus(),
      camundaInstanceSearch: null,
      selectedDefinitionId: null,
      searchActivities: [],
      searchBusinessKey: null,
      searchActivityId: null,
      searchWithIncident: false,
      searchIncludeFinished: false
    }
  },

  created () {
    this.camundaStatus.load()

    useEvent().registerListener(this, 'camunda-definition-select', definition => {
      if (definition) {
        this.selectedDefinitionId = definition.id
        this.searchActivities = definition.activities
        this.searchBusinessKey = null
        this.searchActivityId = null
        this.searchWithIncident = false
        this.searchIncludeFinished = false
        this.searchInstances()
      } else {
        this.selectedDefinitionId = null
        this.camundaInstanceSearch = null
      }
    })

    useEvent().registerListener(this, 'camunda-activity-instances-click', activity => {
      this.selectedDefinitionId = activity.definitionId
      this.searchActivities = this.camundaStatus.definitions.find(definition => definition.id === activity.definitionId).activities
      this.searchBusinessKey = null
      this.searchActivityId = activity.id
      this.searchWithIncident = false
      this.searchIncludeFinished = false
      this.searchInstances()
    })

    useEvent().registerListener(this, 'camunda-activity-incidents-click', activity => {
      this.selectedDefinitionId = activity.definitionId
      this.searchActivities = this.camundaStatus.definitions.find(definition => definition.id === activity.definitionId).activities
      this.searchBusinessKey = null
      this.searchActivityId = activity.id
      this.searchWithIncident = true
      this.searchIncludeFinished = false
      this.searchInstances()
    })

    useEvent().registerListener(this, 'camunda-action', () => {
      this.camundaStatus.loader.refresh()
      if (this.camundaInstanceSearch) {
        this.camundaInstanceSearch.loader.refresh()
      }
    })

    useRefresh().registerListener(this, () => {
      this.camundaStatus.loader.backgroundRefresh()
      if (this.camundaInstanceSearch) {
        this.camundaInstanceSearch.loader.backgroundRefresh()
      }
    })
  },

  beforeDestroy () {
    useEvent().unregisterListener(this)
    useRefresh().unregisterListener(this)
  },

  methods: {
    async searchInstances () {
      this.camundaInstanceSearch = useCamundaInstanceSearch(this.selectedDefinitionId, this.searchBusinessKey, this.searchActivityId, this.searchWithIncident, this.searchIncludeFinished)
      await this.camundaInstanceSearch.search()
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
      }, 100)
      // this.$nextTick(() => {
      //   window.scrollTo({
      //     top: document.body.scrollHeight,
      //     behavior: 'smooth'
      //   })
      //   // window.scrollTo(0, document.body.scrollHeight)
      // })
    }
  }
}
</script>
