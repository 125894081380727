<template>
  <t-edit-dialog
    id="qr-code-order"
    :action-chips="[
        {
          label: 'label.downloadCsv',
          icon: 'mdi-download',
          hideForNew: true,
          handler: qrCodeOrder.downloadCsv
        }
    ]"
    :delete-handler="qrCodeOrder.delete"
    :input-fields="[
        {
          key: 'orderDate',
          type: 'date',
          required: true
        },
        {
          key: 'quantity',
          type: 'number',
          disabledHandler: item => !!item.quantity,
          required: true,
          minValue: 1
        },
        {
          key: 'provider',
          type: 'text',
        },
        {
          key: 'reference',
          type: 'text',
        },
        {
          key: 'receivedDate',
          type: 'date',
        },
        {
          key: 'processedDate',
          type: 'date',
          readOnly: true
        },
        {
          key: 'csv',
          type: 'file',
          label: 'label.csvFile',
          accept: '.csv', icon: 'mdi-file-delimited', disabledHandler: item => item.processedDate != null
        }
    ]"
    :item="qrCodeOrder"
    :save-handler="qrCodeOrder.save"
    :uuid-item="true"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useQrCodeOrder from '@/app/composables/qrcode/useQrCodeOrder'

export default {
  data () {
    return {
      qrCodeOrder: useQrCodeOrder()
    }
  },

  created () {
    useEvent().registerListener(this, 'qr-code-order-add', () => {
      this.qrCodeOrder.setData(null)
      this.$dialog.openDialog('qr-code-order')
    })
    useEvent().registerListener(this, 'qr-code-order-edit', qrCodeOrder => {
      this.qrCodeOrder.setData(qrCodeOrder)
      this.$dialog.openDialog('qr-code-order')
    })
    useEvent().registerListener(this, ['qr-code-order-save', 'qr-code-order-delete'], () => {
      this.$dialog.closeDialog('qr-code-order')
    })
  }
}
</script>
