import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useSubscription from '@/app/composables/subscription/useSubscription'

export default function () {
  const subscriptionList = Vue.observable({
    subscriptions: [],
    fetch: null
  })

  subscriptionList.refresh = async () => {
    subscriptionList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/subscriptions`)
    if (await subscriptionList.fetch.promise) {
      subscriptionList.subscriptions = subscriptionList.fetch.data.map(subscriptionData => {
        const subscription = useSubscription()
        subscription.setData(subscriptionData)
        return subscriptionData
      })
    }
  }

  return subscriptionList
}
