import useSearch from '@/lib/composables/useSearch'
import useQrCodeOrder from '@/app/composables/qrcode/useQrCodeOrder'

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/admin/qr-code-orders`, data => {
    const qrCodeOrder = useQrCodeOrder()
    qrCodeOrder.setData(data)
    return qrCodeOrder
  })
}
