<template>
  <t-page>
    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('card-order-add')
        }
      ]"
      :collapsible="true"
      :default-collapsed="true"
      :fetch="cardOrderSearch.loader"
      title="label.cardOrders"
    >
      <card-order-table :card-order-search="cardOrderSearch"/>
    </t-content-card>

    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('card-assignment-add')
        }
      ]"
      :collapsible="true"
      :default-collapsed="true"
      :fetch="cardAssignmentSearch.loader"
      title="label.cardAssignments"
    >
      <card-assignment-table :card-assignment-search="cardAssignmentSearch"/>
    </t-content-card>

    <t-content-card
      :fetch="cardSearch.loader"
      title="label.cards"
    >
      <card-table :card-search="cardSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useTenantSearch from '@/app/composables/tenant/useTenantSearch'
import useCardAssignmentSearch from '@/app/composables/card/useCardAssignmentSearch'
import useCardOrderSearch from '@/app/composables/card/useCardOrderSearch'
import useCardSearch from '@/app/composables/card/useCardSearch'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      cardOrderSearch: useCardOrderSearch(),
      cardAssignmentSearch: useCardAssignmentSearch(),
      cardSearch: useCardSearch(),
      tenantSearch: useTenantSearch(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, ['card-delete', 'card-save', 'card-order-save', 'card-assignment-process-done'], () => {
      console.log('CARD_SAVE')
      this.cardSearch.refresh()
    })
    useEvent().registerListener(this, ['card-order-save', 'card-order-delete'], () => {
      this.cardOrderSearch.refresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
